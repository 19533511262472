/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card, Checkbox, FormControlLabel, Button, Box, Typography, CardActions, RadioGroup, Radio, CircularProgress, LinearProgress, FormControl, FormGroup, FormHelperText,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { formatDate } from '../../utils/formatters';
import AppContext from '../../context';
import APIWrapper from '../../utils/graphqlwrapper';
import Alert from '../../utils/Alert';
import logger from '../../utils/logger';

function AdsResultsReport() {
  useEffect(() => {
    document.title = 'MissionPortal | SSR Ads Reports';
  }, []);
  const param = useParams();
  const { setShouldLogOut } = useContext(AppContext);
  const [adsResultsUpdateLastRefreshed, setAdsResultsUpdateLastRefreshed] = useState();
  const [roasUpdateLastRefreshed, setRoasUpdateLastRefreshed] = useState();
  const [error, setError] = useState(null);
  const [formError, setFormError] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [isFetchingTimestamps, setIsFetchingTimestamps] = useState(false);
  const [isRunningDatabricksJob, setIsRunningDatabricksJob] = useState(false);
  // set default form data
  const [formData, setFormData] = useState({
    clients: param.client,
    typeOfReport: 'adsReport',
    daily: false,
    weekly: false,
    monthly: false,
    refresh_data: false,
  });

  const {
    daily, weekly, monthly, refresh_data, typeOfReport,
  } = formData;

  const minStartDate = dayjs('2000-01-01T00:00:00.000');
  const maxEndDate = dayjs();

  const shortcutsItems = [
    { label: 'Reset', getValue: () => [null, null] },
  ];

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };

  const handleRadioButtonChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateRangeChange = (newValue) => {
    /**
     * Handles changes made with the MUI date range picker and formats dates
     * The user selects a start date and end date, the parameters for this function is an array with 2 values
     * The date range selection is optional, so if both values are null it is fine
     */
    const dateRange = [];
    setError(null);

    // check if users are missing a start or an end date which will throw an error
    if (newValue.includes(null)) {
      // one value is missing
      if (!newValue.every((x) => x === null)) {
        setError('missingValue');
      }
    } else {
      newValue.forEach((d) => {
        const date = new Date(d.$d);
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        dateRange.push(formattedDate);
      });
    }

    setFormData({ ...formData, start: dateRange[0], end: dateRange[1] });
  };

  const errorMessage = React.useMemo(() => {
    switch (error) {
      case 'minDate': {
        return 'Please select a date after 01-01-2000';
      }
      case 'disableFuture': {
        return 'You cannot select a date in the future';
      }
      case 'invalidDate': {
        return 'Invalid date format';
      }
      case 'missingValue': {
        return 'Please select both start and end date';
      }
      default: {
        return '';
      }
    }
  }, [error]);

  const databricksTableDataFetch = async () => {
    /**
     * Get the latest timestamp for changes from the datalake table so users know if they need to refresh data for the report
     */

    let results;
    const sqlQuery = ["select max(timestamp) AS timestamp from (describe history prod.ads_reporting.roas_facts) where operation = 'CREATE OR REPLACE TABLE AS SELECT'", "select max(timestamp) AS timestamp from (describe history prod.ads_reporting.ads_facts_commission) where operation = 'CREATE OR REPLACE TABLE AS SELECT'"];

    try {
      results = await APIWrapper.queryApi(
        { query: 'databricksTableDataFetch' },
        setShouldLogOut,
        { sqlQuery },
      );
    } catch (err) {
      logger.error(err);
    }
    if (results.data[0].length === 0) {
      // if no data from tables, then return an empty array so there aren't any errors when we map through this data
      return [];
    }
    return JSON.parse(results.data[0]);
  };

  const runDatabricksJob = async () => {
    /**
     * Kick off databricks job to send report to Slack through query using formData that user entered
     * @returns {object} run_id: identifier for run, number_in_job: same identifier as run_id
    */
    let results;
    try {
      setIsRunningDatabricksJob(true);
      results = await APIWrapper.queryApi(
        { query: 'databricksRunJob' },
        setShouldLogOut,
        formData,
      );
    } catch (err) {
      logger.error(err);
    }
    setIsRunningDatabricksJob(false);
    return results;
  };

  const formatFormData = async () => {
    /**
    * Format formData user entered to make sure it matches what the Databricks API and specific job expects
     */
    const breakdownVals = ['daily', 'weekly', 'monthly'];
    const stringsArray = [];

    // set jobId based on what report user chose so we know which job to run
    if (formData.typeOfReport === 'roas') {
      formData.jobId = '736466298784452';
    } else if (formData.typeOfReport === 'adsReport') {
      formData.jobId = '729913246346490';
      // this is how we will build an object for breakdown as the databricks job expects
      // if the value corresponding to the keys specified in breakdownVals is true
      // we will format the breakdown object then remove the initial values from formData
      breakdownVals.forEach((breakdownKey) => {
        if (formData[breakdownKey] === true) {
          stringsArray.push(breakdownKey);
        }
        delete formData[breakdownKey];
      });
      formData.breakdown = stringsArray.join(', ');
    }
  };

  const onSubmit = async () => {
    /**
     * Generate and validate entry for user to run report
     * Display alert message if request was sent successfully or error if there are issues
    */

    // Throw error if breakdown is missing and typeOfReport is adsReport
    let hasError = false;
    if (typeOfReport === 'adsReport' && (!daily && !weekly && !monthly)) {
      hasError = true;
      setFormError(true);
    } else {
      setFormError(false);
    }

    if (!error && !hasError) {
      await formatFormData();
      const response = await runDatabricksJob(formData);
      const responseData = JSON.parse(response.data[0]);
      // Databricks did not send a successful response
      if (!responseData.run_id) {
        setAlertMessage({
          severity: 'error',
          message: 'There was an error sending the request. Please try again or report this issue in tools-help.',
        });
      } else {
        setAlertMessage({
          severity: 'success',
          message: `Request successfully sent. Please allow 20-30 minutes and check the ${param.client}'s ads reporting Slack channel for the requested report.`,
        });
      }
    } else {
      // there are errors the user needs to fix before submitting the form
      setAlertMessage({
        severity: 'error',
        message: 'Please fix errors and try again',
      });
    }
  };

  useEffect(() => {
    setIsFetchingTimestamps(true);
    databricksTableDataFetch().then((data) => {
      if (data.length > 0) {
        setRoasUpdateLastRefreshed(data[0][0].timestamp);
        setAdsResultsUpdateLastRefreshed(data[1][0].timestamp);
        setIsFetchingTimestamps(false);
      }
    });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h2>Ads Reports</h2>
      </header>
      <h5>Generates ads report that will be sent through slack</h5>
      <div id="alert-message">
        {alertMessage
          && (
            <Alert
              alert={alertMessage}
            />
          )}
      </div>
      <Box
        width={800}
        display="flex"
        alignItems="center"
        margin="auto"
        marginTop="10px"
      >
        <Card sx={{ width: '100%', padding: '20px' }}>
          {isFetchingTimestamps ? (
            <>
              <Typography align="center" component="div" sx={{ marginTop: '15px' }}>
                Fetching when data was last updated
              </Typography>
              <LinearProgress sx={{ marginTop: '15px', width: '100%' }} />
            </>
          )
            : (
              <>
                <Typography align="center" gutterBottom variant="h5" component="div">
                  Re-run Ads Report
                </Typography>
                <p>Select type of report *</p>
                <RadioGroup required row aria-label="report-type" name="typeOfReport" defaultValue="adsReport">
                  <FormControlLabel
                    value="adsReport"
                    control={(
                      <Radio
                        onChange={handleRadioButtonChange}
                      />
                    )}
                    label="Ads Report"
                  />
                  <FormControlLabel
                    value="roas"
                    control={(
                      <Radio
                        onChange={handleRadioButtonChange}
                      />
                    )}
                    label="ROAS"
                  />
                </RadioGroup>
                {roasUpdateLastRefreshed && (
                  <Typography variant="subtitle" color="text.secondary" component="div">
                    ROAS data last updated:
                    {' '}
                    {formatDate(roasUpdateLastRefreshed)}
                  </Typography>
                )}
                {roasUpdateLastRefreshed && (
                  <Typography variant="subtitle" color="text.secondary" component="div">
                    Ads results data last updated:
                    {' '}
                    {formatDate(adsResultsUpdateLastRefreshed)}
                  </Typography>
                )}
                <p style={formData.typeOfReport === 'roas' ? { color: '#8D8E8E' } : { color: '#000' }}>Breakdown *</p>
                <FormControl disabled={formData.typeOfReport === 'roas'}>
                  <FormGroup row>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          name="daily"
                          onChange={handleCheckboxChange}
                          checked={daily}
                        />
                      )}
                      label="Daily"
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          name="weekly"
                          onChange={handleCheckboxChange}
                          checked={weekly}
                        />
                      )}
                      label="Weekly"
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox
                          name="monthly"
                          onChange={handleCheckboxChange}
                          checked={monthly}
                        />
                      )}
                      label="Monthly"
                    />
                  </FormGroup>
                  <FormHelperText sx={{ display: formError ? 'block' : 'none' }}>Please select a breakdown for the report</FormHelperText>
                </FormControl>
                {/* date picker */}
                <p style={formData.typeOfReport === 'roas' ? { color: '#8D8E8E' } : { color: '#000' }}>Select date range (optional)</p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateRangePicker
                    localeText={{ start: 'Start date', end: 'End date' }}
                    disableFuture
                    minDate={minStartDate}
                    maxDate={maxEndDate}
                    format="MM-DD-YYYY"
                    calendars={1}
                    onAccept={(newValue) => handleDateRangeChange(newValue)}
                    onError={(newError) => setError(newError)}
                    disabled={formData.typeOfReport === 'roas'}
                    slotProps={{
                      shortcuts: {
                        items: shortcutsItems,
                      },
                      textField: {
                        helperText: errorMessage,
                      },
                    }}
                    sx={{ marginBottom: '10px' }}
                  />
                </LocalizationProvider>
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="refresh_data"
                      onChange={handleCheckboxChange}
                      checked={refresh_data}
                    />
                  )}
                  label="Refresh data"
                />
                <Typography variant="subtitle2" color="text.secondary">
                  Note that if you refresh the data, the report can take about 30 minutes to be delivered through Slack
                </Typography>
                <CardActions className="view-report-button">
                  <Button
                    variant="contained"
                    sx={{
                      width: '50%', padding: '12px',
                    }}
                    onClick={onSubmit}
                  >
                    {isRunningDatabricksJob ? <CircularProgress /> : 'Run Report'}
                  </Button>
                </CardActions>
              </>
            )}
        </Card>
      </Box>
    </div>

  );
}

export default AdsResultsReport;
