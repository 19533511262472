/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Card } from '@mui/material';
import { formatDate } from '../../utils/formatters';

import ClientTextField from '../clientFields/ClientTextField';
import ClientCheckBox from '../clientFields/ClientCheckbox';
import ClientDropDown from '../clientFields/ClientDropDown';
import ClientTextarea from '../clientFields/ClientTextarea';

function DataIngest({
  validateFunc, setFunction, data, permissions, structure, timeStamps, hasTDCAdminPermission, createdAt,
}) {
  const setter = setFunction;
  const [liveData, setLiveData] = useState({ ...data });
  const [userPermissions] = useState({ ...permissions });
  const [dataStructure, setDataStructure] = useState({ ...structure });
  const [formattedIsActive, setFormattedIsActive] = useState(null);
  const [formattedFeaturize, setFormattedFeaturize] = useState(null);

  useEffect(() => {
    if (timeStamps && timeStamps.is_active) {
      setFormattedIsActive(formatDate(timeStamps.is_active));
    }
    if (timeStamps && timeStamps.featurize) {
      setFormattedFeaturize(formatDate(timeStamps.featurize));
    }
    setLiveData(data);
    setDataStructure(structure);
  }, [data, timeStamps]);

  const peoplePlatformLiveData = liveData.people_platform
    ? liveData.people_platform.join(', ')
    : null;
  const actionPlatformLiveData = liveData.action_platform
    ? liveData.action_platform.join(', ')
    : null;
  const transactionPlatformLiveData = liveData.transaction_platform
    ? liveData.transaction_platform.join(', ')
    : null;
  const exhaustPlatformLiveData = liveData.exhaust_platform
    ? liveData.exhaust_platform.join(', ')
    : null;
  const smsPlatformLiveData = liveData.sms_platform
    ? liveData.sms_platform.join(', ')
    : null;

  return (
    <div id="data-ingest">
      <div id="ingest-basics">
        <div id="ingest-checks">
          <div className="timestamp">
            <ClientCheckBox
              setFunction={setter}
              data={liveData.is_active}
              attribute="is_active"
              label="Active"
              permissions={userPermissions}
              structure={dataStructure}
              isDisabled={!hasTDCAdminPermission}
            />
            {/* display last timestamp update or when the client was created */}
            <p>
              Last updated on
              {' '}
              {formattedIsActive || (formatDate(createdAt))}
            </p>
          </div>
          <ClientCheckBox
            setFunction={setter}
            data={liveData.is_strategy_partner}
            attribute="is_strategy_partner"
            label="Strategy Partner"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasTDCAdminPermission}
          />
          <ClientCheckBox
            setFunction={setter}
            data={liveData.exclude_from_match_qualifier}
            attribute="exclude_from_match_qualifier"
            label="Exclude from Match Qualifier"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasTDCAdminPermission}
          />
          <div className="timestamp">
            <ClientCheckBox
              setFunction={setter}
              data={liveData.featurize}
              attribute="featurize"
              label="Featurize"
              permissions={userPermissions}
              structure={dataStructure}
              isDisabled={!hasTDCAdminPermission}
            />
            {/* display last timestamp update or when the client was created */}
            <p>
              Last updated on
              {' '}
              {formattedFeaturize || (formatDate(createdAt))}
            </p>
          </div>
        </div>

        <Card variant="outlined" sx={{ p: 3 }}>
          <h2>People Platform</h2>
          <p>Email, subscription status.</p>
          <ClientDropDown
            setFunction={setter}
            data={peoplePlatformLiveData}
            attribute="people_platform"
            label="Data Source"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasTDCAdminPermission}
          />
        </Card>
        <Card variant="outlined" sx={{ p: 3 }}>
          <h2>Action Platform</h2>
          <p>Petitions, surveys, advocacy calls, event RSVP’s, volunteers.</p>
          <ClientDropDown
            setFunction={setter}
            data={actionPlatformLiveData}
            attribute="action_platform"
            label="Data Source"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasTDCAdminPermission}
          />
        </Card>
        <Card variant="outlined" sx={{ p: 3 }}>
          <h2>Transaction Platform</h2>
          <p>Donations.</p>
          <ClientDropDown
            setFunction={setter}
            data={transactionPlatformLiveData}
            attribute="transaction_platform"
            label="Data Source"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasTDCAdminPermission}
          />
        </Card>
        <Card variant="outlined" sx={{ p: 3 }}>
          <h2>Email Exhaust Platform</h2>
          <p>Data on opens, clicks, and unsubscribes</p>
          <ClientDropDown
            setFunction={setter}
            data={exhaustPlatformLiveData}
            attribute="exhaust_platform"
            label="Data Source"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasTDCAdminPermission}
          />
        </Card>
        <Card variant="outlined" sx={{ p: 3 }}>
          <h2>SMS Platform</h2>
          <p>Data on opens, clicks, and unsubscribes</p>
          <ClientDropDown
            setFunction={setter}
            data={smsPlatformLiveData}
            attribute="sms_platform"
            label="Data Source"
            permissions={userPermissions}
            structure={dataStructure}
            isDisabled={!hasTDCAdminPermission}
          />
        </Card>
        <Card variant="outlined" sx={{ p: 3 }}>
          <h2>Pause Data Config</h2>
          <p>
            This field is for configuring whether the client&apos;s pipelines are paused or active.
          </p>
          <ClientTextarea
            validateFunc={validateFunc}
            setFunction={setter}
            data={liveData.pause_data}
            attribute="pause_data"
            label="Pause Data Config"
            permissions={userPermissions}
            structure={dataStructure}
          />
        </Card>
      </div>
      <Card
        id="ingest-data-source"
        className="client-card"
        variant="outlined"
        sx={{ p: 3 }}
      >
        <h2>Data Source IDs</h2>
        <p>Specific IDs for Data Sources</p>
        <ClientTextField
          setFunction={setter}
          data={liveData.sfg_surveys_client_ids}
          attribute="sfg_surveys_client_ids"
          label="Sign For Good ID"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.goop_client_ids}
          attribute="goop_client_ids"
          label="Goop Client ID"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.totem_client_ids}
          attribute="totem_client_ids"
          label="Totem Client ID"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.actblue_entity_ids}
          attribute="actblue_entity_ids"
          label="Act Blue Entity IDs"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          // type should be text, will get converted to array of numbers in setter function
          setFunction={setter}
          data={liveData.stw_subaccount_ids}
          attribute="stw_subaccount_ids"
          label="Scale to Win Subaccount IDs"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.salsa_organization_key}
          attribute="salsa_organization_key"
          label="Salsa Organization Key"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.ngp_everyaction_database_name}
          attribute="ngp_everyaction_database_name"
          label="NGP Every Action Database Name"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.ngp_everyaction_server_name}
          attribute="ngp_everyaction_server_name"
          label="NGP Every Action Server Name"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.mailchimp_directory}
          attribute="mailchimp_directory"
          label="Mailchimp Directory"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.salesforce_location}
          attribute="salesforce_location"
          label="Salesforce Location"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.pardot_location}
          attribute="pardot_location"
          label="Pardot Location"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.hub_source_path}
          attribute="hub_source_path"
          label="Hub Source Path"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.facebook_account_ids}
          attribute="facebook_account_ids"
          label="Facebook Account IDs"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.facebook_locations}
          attribute="facebook_locations"
          label="Facebook Locations"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.google_account_ids}
          attribute="google_account_ids"
          label="Google Account IDs"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.bing_account_ids}
          attribute="bing_account_ids"
          label="Bing Account IDs"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.twitter_account_ids}
          attribute="twitter_account_ids"
          label="Twitter Account IDs"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.outbrain_account_ids}
          attribute="outbrain_account_ids"
          label="Outbrain Account IDs"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.code_dictionary_url}
          attribute="code_dictionary_url"
          label="Code Dictionary URL"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.classy_org_id}
          attribute="classy_org_id"
          label="Classy Org ID's"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.ngp_actblue_manual_codes}
          attribute="ngp_actblue_manual_codes"
          label="NGP Actblue Manual Codes"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.tatango_client_id}
          attribute="tatango_client_id"
          label="Tatango Client ID"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.inbox_monster_rule}
          attribute="inbox_monster_rule"
          label="Inbox Monster Rule"
          permissions={userPermissions}
          structure={dataStructure}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.campaign_monitor_location}
          attribute="campaign_monitor_location"
          label="Campaign Monitor Location"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
        <ClientTextField
          setFunction={setter}
          data={liveData.hubspot_stitch_integration_name}
          attribute="hubspot_stitch_integration_name"
          label="HubSpot Stitch Integration Name"
          permissions={userPermissions}
          structure={dataStructure}
          isDisabled={!hasTDCAdminPermission}
        />
      </Card>
    </div>
  );
}

export default DataIngest;

DataIngest.propTypes = {
  permissions: PropTypes.oneOfType([PropTypes.object]),
  structure: PropTypes.oneOfType([PropTypes.object]),
  setFunction: PropTypes.func,
  validateFunc: PropTypes.func,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  timeStamps: PropTypes.oneOfType([PropTypes.object]),
  hasTDCAdminPermission: PropTypes.bool.isRequired,
  createdAt: PropTypes.string,
};
