/* eslint-disable max-len */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Button, Tooltip } from '@mui/material';

import Loading from '../components/Loading';
import { currencyFormatter } from '../utils/formatters';
import AppContext from '../context';
import APIWrapper from '../utils/graphqlwrapper';
import FallbackOnError from '../components/FallbackOnError';
import { logReactErrBoundaryError } from '../utils';
import logger from '../utils/logger';
import CodesCard from '../components/CodesCard';
import DataRecencyCard from '../components/DataRecencyCard';

import GoopLogo from '../assets/goop-logo.png';
import TotemLogo from '../assets/totem-logo.png';

function ClientLandingPage(props) {
  const [codesCount, setCodesCount] = useState();
  const [todaysRevenue, setTodaysRevenue] = useState();
  const [monthsRevenue, setMonthsRevenue] = useState();
  const [updatedDateTime, setUpdatedDateTime] = useState('');
  const [updateDataRecency, setUpdateDataRecency] = useState([]);
  const [clientInfo, setClientInfo] = useState({});
  const [goopIds, setGoopIds] = useState([]);
  const [totemIds, setTotemIds] = useState([]);
  const { setShouldLogOut } = useContext(AppContext);
  const param = useParams();
  const navigate = useNavigate();
  const { hasTDCAdminPermission, hasDataEngineerPermission } = props;

  let thirtyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 30)); // -30 days from now
  thirtyDaysAgo = new Date(thirtyDaysAgo.setHours(0, 0, 0, 0));
  const today = `${new Date().toISOString().slice(0, 10)}T00:00:00.000Z`;
  const firstDayOfMonth = `${new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10)}T00:00:00.000Z`;

  const codesSearchObj = {
    client_id: { eq: param.client },
    and: {
      most_recent_transaction_date: { gte: thirtyDaysAgo.toISOString() },
      and: {
        or: [
          { source: { eq: '' } },
          { source: { exists: 'false' } },
        ],
      },
    },
  };

  const buildTransactionSearchObj = (date) => ({
    /**
     * Build the search criteria for the revenue API call
     * @param {string} date - the date to filter the transactions by
     */
    client_id: { eq: param.client },
    and: {
      transaction_dt: { gte: date },
      and: {
        exclude_flag: { ne: true },
        refund_flag: { ne: true },
      },
    },
  });

  useEffect(() => {
    const fetchClientInfo = async () => {
      /**
       * Get client information
       */
      let results;
      try {
        results = await APIWrapper.queryApi(
          { query: 'getClient' },
          setShouldLogOut,
          { client_id: param.client },
        );

        // if we have client data returned then let's set goop and totem ids to state if the  values are not null
        if (results.data[0].length !== 0) {
          if (results.data[0][0].goop_client_ids !== null) {
            setGoopIds(results.data[0][0].goop_client_ids);
          } if (results.data[0][0].totem_client_ids !== null) {
            setTotemIds(results.data[0][0].totem_client_ids);
          }
        }
      } catch (err) {
        logger.error(err);
      }
      return results.data[0][0];
    };

    const fetchCodesCount = async () => {
      /**
       * Get the count of ungrouped codes from the last year
       */
      let results;
      try {
        results = await APIWrapper.queryApi(
          { query: 'searchCodes' },
          setShouldLogOut,
          {
            filter: codesSearchObj,
            limit: 1,
          },
        );
      } catch (err) {
        logger.error(err);
      }
      return results.total;
    };

    const fetchRevenue = async (searchCriteria) => {
      /**
       * Get the sum of the amount of transactions based on a specific time frame
       * @param {object} searchCriteria - search criteria for the API call
       */
      let results;
      try {
        results = await APIWrapper.queryApi(
          { query: 'searchTransactions' },
          setShouldLogOut,
          {
            filter: searchCriteria,
            aggregates: { field: 'amount', name: 'amountAgg', type: 'sum' },
            limit: 1,
          },
        );
      } catch (err) {
        logger.error(err);
      }
      if (!results.aggregateItems) { // handle initial call to db that doesn't return anything...?
        return '';
      }
      const formattedCurrency = currencyFormatter(results.aggregateItems[0].result.value);
      return formattedCurrency;
    };

    const fetchTransactionUpdatedTime = async () => {
      /**
       * Get the last updated time of the transaction data
       */
      let results;
      try {
        results = await APIWrapper.queryApi(
          { query: 'getDatalakeHistory' },
          false,
          { sync_name: 'Transactions_sync' },
        );
      } catch (err) {
        logger.error(err);
      }
      if (results.data[0].length === 0) {
        return '';
      }
      const utcTime = `${results.data[0][0].last_updated_endTime}.000Z`;
      const formattedDateTime = moment(utcTime).tz('America/New_York').format('MM/DD/YYYY hh:mma z');
      return formattedDateTime;
    };

    const databricksTableDataFetch = async () => {
      /**
       * Get the latest data from the insights.tableau_most_recent_table_dates_by_client_by_core_table datalake table
       */

      let results;
      try {
        results = await APIWrapper.queryApi(
          { query: 'databricksTableDataFetch' },
          setShouldLogOut,
          { sqlQuery: [`SELECT platform, last_date, days_since FROM insights.tableau_most_recent_table_dates_by_client_by_core_table where core_table="transaction" and client='${param.client}' ORDER BY last_date desc;`] },
        );
      } catch (err) {
        logger.error(err);
      }
      if (results.data[0].length === 0) {
        // if no data return empty array so there aren't any errors when we map through this data
        return [];
      }
      return JSON.parse(results.data[0][0]);
    };

    const filterForTodaysRev = buildTransactionSearchObj(today);
    const filterForMonthsRev = buildTransactionSearchObj(firstDayOfMonth);
    fetchClientInfo().then((data) => setClientInfo(data));
    fetchCodesCount().then((data) => setCodesCount(data));
    fetchRevenue(filterForTodaysRev).then((data) => setTodaysRevenue(data));
    fetchRevenue(filterForMonthsRev).then((data) => setMonthsRevenue(data));
    fetchTransactionUpdatedTime().then((data) => setUpdatedDateTime(data));
    databricksTableDataFetch().then((data) => setUpdateDataRecency(data[0]));
  }, []);

  useEffect(() => {
    document.title = `MissionPortal | ${param.client}`;
  }, []);

  const handleSettingsClick = () => {
    navigate(`/client/${param.client}/settings`, {
      replace: false,
      state: { client: param.client },
    });
  };

  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
    >
      {!codesCount && !todaysRevenue && !monthsRevenue && !updatedDateTime && !updateDataRecency
        ? (<Loading />)
        : (
          <>
            <div className="landing-header">
              {(hasTDCAdminPermission || hasDataEngineerPermission)
            && <Button variant="contained" onClick={() => handleSettingsClick()}>Client Settings</Button>}
            </div>
            <h3 className="section-header-text">Overview</h3>
            <div className="codes-rev-container">
              <CodesCard topText="Ungrouped codes" count={codesCount} includeToolTip toolTipText="Go to Codes" bottomText="in the past 30 days" />
              <CodesCard topText="Raised Today" revenue={todaysRevenue} bottomText={`Last updated: ${updatedDateTime}`} />
              <CodesCard topText="Raised this Month" revenue={monthsRevenue} bottomText={`Last updated: ${updatedDateTime}`} />
            </div>
            <h3 className="section-header-text">Data Recency (Last Update)</h3>
            <div className="data-recency-container">
              <DataRecencyCard platform="Datalake" timestamp={updatedDateTime} />
              {updateDataRecency
                .filter((data) => clientInfo.transaction_platform.includes(data.platform))
                .map((data) => (
                  <DataRecencyCard key={data.platform} platform={data.platform} lastRefresh={data.days_since} timestamp={moment(data.last_date).tz('America/New_York').format('MM/DD/YYYY hh:mma z')} />
                ))}
            </div>
            {goopIds.length === 0 && totemIds.length === 0 ? '' : <h3 className="section-header-text">Other Apps</h3>}
            <div className="goop-totem-links-container">
              {goopIds.map((data) => (
                <div key={data} className="goop-links">
                  <Tooltip title={`Go to Goop client: ${data}`} placement="bottom">
                    <Link to={`https://goop.missionwired.com/#/client/${data}/groupings`} target="_blank">
                      <img src={GoopLogo} alt="Goop logo" />
                    </Link>
                  </Tooltip>
                  <h4>
                    {`Goop ID: ${data}`}
                  </h4>
                </div>
              ))}
              {totemIds.map((data) => (
                <div key={data} className="totem-links">
                  <Tooltip title={`Go to Totem client: ${data}`} placement="bottom">
                    <Link to={`https://totem.missionwired.com/#!/home/${data}`} target="_blank">
                      <img src={TotemLogo} alt="Totem logo" />
                    </Link>
                  </Tooltip>
                  <h4>
                    {`Totem ID: ${data}`}
                  </h4>
                </div>
              ))}
            </div>
          </>
        )}
    </ErrorBoundary>
  );
}

export default ClientLandingPage;

ClientLandingPage.propTypes = {
  hasTDCAdminPermission: PropTypes.bool.isRequired,
  hasDataEngineerPermission: PropTypes.bool.isRequired,
};
