import { Logger } from 'aws-amplify';
import getConfig from '../config';

const config = getConfig();

// Set the level for when you need more logging to debug
// https://docs.amplify.aws/lib/utilities/logger/q/platform/js/#setting-logging-levels
const logger = new Logger('ReallyCoolLogName', config.LOGGING_LEVEL);

export default logger;
