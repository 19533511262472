import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import HelpIcon from '@mui/icons-material/Help';
import { FormControlLabel, Tooltip } from '@mui/material';
import ClientForm from './ClientForm';

class ClientCheckBox extends ClientForm {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
    };
  }

  render() {
    return (
      <span>
        <FormControlLabel
          key={this.state.attribute}
          control={(
            <Checkbox
              data-testid={this.state.testingId}
              checked={this.state.data || false}
              onChange={this.updateClientStateCheckbox}
              disabled={this.props.isDisabled || this.state.isDisabled}
            />
            )}
          className="checkbox-field"
          label={this.state.label}
        />
        {this.state.isDisabled
            && this.state.attribute === 'is_advantage_member' && (
              <Tooltip
                title="To update this field, contact: Todd Plants, Nathan Chong, or Michael Milton."
                placement="right-start"
                arrow
              >
                <HelpIcon fontSize="small" color="disabled" />
              </Tooltip>
        )}
      </span>
    );
  }
}

export default ClientCheckBox;
