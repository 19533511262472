/* eslint-disable react/jsx-props-no-spreading */
import { LocalizationProvider, DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import PropTypes from 'prop-types';
import logger from '../utils/logger';

function CustomDateRangePicker({ setCriteria, criteria }) {
  let start = dayjs();
  let end = dayjs();
  if (criteria.length === 2 && Array.isArray(criteria)) {
    start = dayjs(criteria[0]);
    end = dayjs(criteria[1]);
  }
  const [datePicked, setDatePicked] = useState([start, end]);
  const updateDate = (newValue) => {
    setDatePicked(newValue);
    const dateRange = [dayjs(newValue[0]).format('YYYY-MM-DD'), dayjs(newValue[1]).format('YYYY-MM-DD')];
    setCriteria(dateRange);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        startText="Check-in"
        endText="Check-out"
        value={datePicked}
        onChange={(newValue) => {
          updateDate(newValue);
        }}
        slotProps={{
          textField: { variant: 'outlined' },
        }}
        sx={{ width: 275 }}
      />
    </LocalizationProvider>
  );
}

CustomDateRangePicker.propTypes = {
  setCriteria: PropTypes.func.isRequired,
  criteria: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

function CustomDatePicker({ setCriteria, criteria }) {
  let start = dayjs();
  try {
    start = dayjs(criteria);
  } catch (err) {
    logger.error(err);
  }

  const [datePicked, setDatePicked] = useState(start);
  const updateDate = (newValue) => {
    setDatePicked(newValue);
    const newDate = dayjs(newValue).format('YYYY-MM-DD');
    setCriteria(newDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Date"
        value={datePicked}
        onChange={(newValue) => updateDate(newValue)}
        sx={{ width: 275 }}
      />
    </LocalizationProvider>
  );
}

CustomDatePicker.propTypes = {
  setCriteria: PropTypes.func.isRequired,
  criteria: PropTypes.string.isRequired,
};

function CustomDateTimeRangePicker({ setCriteria, criteria }) {
  let start = dayjs();
  let end = dayjs();
  if (criteria.length === 2 && Array.isArray(criteria)) {
    if (dayjs(criteria[0]).isValid()) {
      start = dayjs(criteria[0]);
    } else {
      const newDate = dayjs(start).format('YYYY-MM-DDTHH:mm:ss');
      setCriteria(newDate);
    }

    if (dayjs(criteria[1]).isValid()) {
      end = dayjs(criteria[1]);
    } else {
      const newDate = dayjs(end).format('YYYY-MM-DDTHH:mm:ss');
      setCriteria(newDate);
    }
  }

  const [datePicked, setDatePicked] = useState([start, end]);
  const updateDate = (newValue, index) => {
    const newRange = [...datePicked];
    newRange[index] = newValue;
    setDatePicked(newRange);
    const dateRange = [dayjs(newRange[0]).toISOString(), dayjs(newRange[1]).toISOString()];
    setCriteria(dateRange);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label="Start"
        value={datePicked[0]}
        onChange={(newValue) => updateDate(newValue, 0)}
        sx={{ width: 225 }}
      />
      -
      <DateTimePicker
        label="End"
        value={datePicked[1]}
        onChange={(newValue) => updateDate(newValue, 1)}
        sx={{ width: 225 }}
      />
    </LocalizationProvider>
  );
}

CustomDateTimeRangePicker.propTypes = {
  setCriteria: PropTypes.func.isRequired,
  criteria: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

function CustomDateTimePicker({ setCriteria, criteria }) {
  let start = dayjs();
  try {
    start = dayjs(criteria);
  } catch (err) {
    logger.error(err);
  }

  const [datePicked, setDatePicked] = useState(start);
  const updateDate = (newValue) => {
    setDatePicked(newValue);
    const newDate = dayjs(newValue).format('YYYY-MM-DDTHH:mm:ss');
    setCriteria(newDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label="Date"
        value={datePicked}
        onChange={updateDate}
        sx={{ width: 275 }}
      />
    </LocalizationProvider>
  );
}

CustomDateTimePicker.propTypes = {
  setCriteria: PropTypes.func.isRequired,
  criteria: PropTypes.string.isRequired,
};

export {
  CustomDateRangePicker,
  CustomDatePicker,
  CustomDateTimeRangePicker,
  CustomDateTimePicker,
};
