/* eslint-disable no-unused-vars */
import React from 'react';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import { opLabels } from '../utils/schemaInfo';

const formatChipCriteria = (criteria, format) => {
  let formattedCriteria = criteria;
  if (format === 'AWSDateTime') {
    formattedCriteria = new Date(criteria).toLocaleString([], {
      year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: '2-digit',
    });
  } else if (format === 'AWSDate') {
    const year = new Date(criteria).getFullYear();
    const month = new Date(criteria).getMonth() + 1;
    const day = new Date(criteria).getDate();
    formattedCriteria = `${month}/${day}/${year}`;
  }
  return formattedCriteria;
};

/**
 * Generates a list of chips based on the search criteria
 * @param {Array} columns - list of columns with their properties
 * @param {Array} search - user-defined search criteria
 * @returns {Array} - list of chip objects
 */
const formatChip = (columns, search) => {
  const colsByField = columns.reduce((acc, curr) => { acc[curr.field] = curr; return acc; }, {});
  const chipList = [];

  search.forEach((criteria, index) => {
    const colName = colsByField[criteria.col].headerName;
    const opLabel = opLabels[criteria.op];

    const colFormat = colsByField[criteria.col].format;
    const critValue = criteria.op === 'between' ? `'${formatChipCriteria(criteria.criteria[0], colFormat)}' and '${formatChipCriteria(criteria.criteria[1], colFormat)}'` : `'${formatChipCriteria(criteria.criteria, colFormat)}'`;

    let currDescription = `${colName} ${opLabel} ${critValue}`;
    if (criteria.children) {
      if (criteria.children.length > 0) {
        criteria.children.forEach((child) => {
          const childOpLabel = opLabels[child.op];
          const childCritValue = child.op === 'between' ? `'${formatChipCriteria(child.criteria[0], colFormat)}' and '${formatChipCriteria(child.criteria[1], colFormat)}'` : `'${formatChipCriteria(child.criteria, colFormat)}'`;
          currDescription += ` OR ${childOpLabel} ${childCritValue}`;
        });
      }
    }
    chipList.push({
      description: currDescription,
      index,
    });
  });
  return chipList;
};

/**
 * Component for displaying advanced search criteria as chips
 * @param {Array} columns - list of cols with their properties
 * @param {Array} lockedCriteria - locked search criteria that the user can't delete
 * @param {Array} userCriteria - user-defined search criteria (can be deleted)
 * @param {function} setUserCriteria - function to set search criteria (used if user deletes chip)
 * @param {function} onDelete - function to handle deletion of a search criteria
 */
function AdvancedSearchChip({
  columns, lockedCriteria = [], userCriteria, setUserCriteria, onDelete,
}) {
  const handleDelete = (index) => {
    const updatedCriteria = [...userCriteria];
    updatedCriteria.splice(index, 1);
    setUserCriteria(updatedCriteria);
    onDelete(index);
  };

  // create a map of columns by field name
  const colsByField = columns.reduce((acc, curr) => {
    acc[curr.field] = curr;
    return acc;
  }, {});

  const lockedCriteriaDescriptions = lockedCriteria.map((item, index) => {
    const colName = colsByField[item.col].headerName || item.col;
    const opLabel = opLabels[item.op] || item.op;
    let description = `${colName} ${opLabel} '${formatChipCriteria(item.criteria)}'`;
    if (opLabel === 'between') {
      const critValue = `${formatChipCriteria(item.criteria[0], colsByField[item.col].format)} and ${formatChipCriteria(item.criteria[1], colsByField[item.col].format)}`;
      description = `${colName} ${opLabel} ${critValue}`;
    }

    if (item.children) {
      if (item.children.length > 0) {
        item.children.forEach((child) => {
          const childOpLabel = opLabels[child.op];
          const childCritValue = child.op === 'between' ? `${formatChipCriteria(child.criteria[0], colsByField[child.col].format)} and ${formatChipCriteria(child.criteria[1], colsByField[child.col].format)}` : formatChipCriteria(child.criteria, colsByField[child.col].format);
          description += ` OR ${childOpLabel} ${childCritValue}`;
        });
      }
    }

    return {
      description,
      index,
    };
  });

  return (
    <div className="chip-list">
      {lockedCriteriaDescriptions.map((item) => (
        <Chip key={`${item.index}`} label={item.description} disabled className="search-chip" />
      ))}
      {userCriteria.map((item) => (
        <Chip key={`${item.description}-${item.index}`} label={item.description} onDelete={() => handleDelete(item.index)} className="search-chip" />
      ))}
    </div>
  );
}

AdvancedSearchChip.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    headerName: PropTypes.string.isRequired,
    format: PropTypes.string,
  })),
  lockedCriteria: PropTypes.arrayOf(PropTypes.shape({
    col: PropTypes.string.isRequired,
    op: PropTypes.string.isRequired,
    criteria: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({
      col: PropTypes.string.isRequired,
      op: PropTypes.string.isRequired,
      criteria: PropTypes.string.isRequired,
    })),
  })).isRequired,
  userCriteria: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
  })),
  setUserCriteria: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default AdvancedSearchChip;

export { formatChip };
