import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Divider,
} from '@mui/material';

function DataRecencyCard(props) {
  // data from insights.tableau_most_recent_table_dates_by_client_by_core_table
  // last refresh: number of days since data was updated
  // platform: transaction CRM platform
  // timestamp: timestamp string for last time data was updated
  const {
    lastRefresh, platform, timestamp,
  } = props;

  function normalize(crmPlatform) {
    /**
    * This function will capitalize the first letters in the platform string
    * It will also check if the platform string include a '_' to then replace with a space
    * An exception is if the platform is ngp_everyaction
    * We will instead change it to NGP/EveryAction so that it looks better
    * @param {crmPlatform} string
    * @return {string} capitalized or formatted crm platform
    */
    let formattedString;
    if (crmPlatform === 'ngp_everyaction') {
      formattedString = 'NGP/EveryAction';
    } else if (crmPlatform.includes('_')) {
      // replace _ in platform names
      formattedString = crmPlatform.replaceAll('_', ' ');
      // capitalize first char in strings
      formattedString = formattedString.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    } else {
      formattedString = crmPlatform.charAt(0).toUpperCase() + crmPlatform.slice(1);
    }
    return formattedString;
  }

  function LastRefreshDayString(numOfDays) {
    /**
    * This function checks how the last refresh string that
    * appears on the card should be formatted
    * depending on number of days since the data
    * was updated for the platform
    * @param {numOfDays} string ex: 'Last Refresh: 1' or undefined
    * @return {string} empty string or formatted string ex: Last Refresh: 1 Day
    */
    let stringToReturn;
    if (!numOfDays) {
      stringToReturn = '';
    } else if (numOfDays === 1) {
      stringToReturn = `Last Refresh: ${numOfDays} Day`;
    } else {
      stringToReturn = `Last Refresh: ${numOfDays} Days`;
    }
    return stringToReturn;
  }

  return (
    <Card className="data-recency-card" variant="outlined" sx={{ p: 3 }}>
      <p className="subtext">{normalize(platform)}</p>
      <Divider />
      <p className="days-since">
        {LastRefreshDayString(lastRefresh)}
      </p>
      <p className="data-recency-last-updated-timestamp">
        {timestamp}
      </p>
    </Card>
  );
}

export default DataRecencyCard;

DataRecencyCard.propTypes = {
  lastRefresh: PropTypes.number,
  platform: PropTypes.string.isRequired,
  timestamp: PropTypes.string,
};
