import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';

export default function DisplayAlert(props) {
  const {
    alert,
  } = props;

  useEffect(() => {
    // run when alert changes
  }, [alert]);

  return (
    <div>
      <Alert severity={alert.severity}>
        {alert.message}
      </Alert>
    </div>
  );
}

DisplayAlert.propTypes = {
  alert: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
