import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';

export default function ReportsCard(props) {
  /**
   * ReportsCard component displays an MUI card with an image, title, description, bullets,
   * and a button to navigate to a specific report.
   * @param {object} props - The props object
   * @param {string} props.image - The image (imported into whatever file is using this component)
   * @param {string} props.imageAlt - The image alt text
   * @param {string} props.title - The title on the card
   * @param {string} props.description - The description on the card
   * @param {array} props.bullets - The bullets on the card
   * @param {string} props.route - The route or page to navigate to when the button is clicked.
   * @param {string} props.externalPageURL - The external page to go to when the button is clicked.
   * @param {string} props.buttonText - The text that should render for the button in the card.
   * NOTE: route and externalPageURL are mutually exclusive. If both are provided, route will be
   * used. You should provide at least one of these props.
   */
  const {
    image, imageAlt, title, description, bullets, route, externalPageURL, buttonText,
  } = props;
  const [useDefaultImage, setUseDefaultImage] = useState(false);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(route, {
      replace: false,
    });
  };

  useEffect(() => {
    if (!image && !imageAlt) {
      setUseDefaultImage(true);
    }
  }, []);

  return (
    <Card sx={{ width: 345 }}>
      {useDefaultImage ? (
        <CardMedia
          sx={{ height: 160, margin: '5px' }}
          image="https://via.placeholder.com/150"
          title="Placeholder"
        />
      ) : (
        <CardMedia
          sx={{ height: 160, margin: '5px' }}
          image={image}
          title={imageAlt}
        />
      )}
      <CardActions className="view-report-button">
        {route ? (
          <Button onClick={() => handleNavigate()} size="small" variant="contained">{buttonText}</Button>
        ) : (
          <Button
            onClick={() => {
              window.open(externalPageURL, '_blank');
            }}
            size="small"
            variant="contained"
          >
            {buttonText}
          </Button>
        )}
      </CardActions>
      <CardActions className="view-report-button" />
      <CardContent>
        <Typography align="center" gutterBottom variant="h7" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description || ''}
        </Typography>
        <ul className="card-list" data-testid="list-parent">
          {bullets.map((bullet) => (
            <Typography key={bullet} variant="body2" color="text.secondary" data-testid="list-child">
              {`- ${bullet}`}
            </Typography>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
}

ReportsCard.propTypes = {
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  bullets: PropTypes.arrayOf(PropTypes.string).isRequired,
  route: PropTypes.string,
  externalPageURL: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
};
