/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import ReportsCard from '../../components/ReportsCard';
import sumBySource from '../../assets/reporting_views/ssr_multi_channel.png';

function SSRMultiChannelLanding() {
  useEffect(() => {
    document.title = 'MissionPortal | SSR Multi-Channel';
  }, []);

  const { client } = useParams();

  return (
    <div className="App">
      <header className="App-header">
        <h2>Strategic Services - Multi-Channel</h2>
      </header>
      <h5>
        View client revenue and sum by source reports.
        <a target="_blank" href="https://missionwired.atlassian.net/wiki/spaces/AAI/overview" rel="noreferrer"> Digital Co-Op Confluence Space.</a>
      </h5>
      <Box sx={{ flexGrow: 1 }}>
        <div className="reporting-row-container">
          <ReportsCard
            image={sumBySource}
            imageAlt="Sum by source report"
            title="Sum by Source"
            description="See client revenue by:"
            bullets={['Channel: Gifts and revenue raised, by day by channel, further broken out by code type and source', 'Codes: Gifts and revenue raised, by day by code, with channel category and source', 'Bundling: Bundling gifts and revenue raised broken out by source']}
            route={`/client/${client}/Strategy/Multi-Channel/sum_by_source`}
            buttonText="View Report"
          />
        </div>
      </Box>
    </div>
  );
}

export default SSRMultiChannelLanding;
