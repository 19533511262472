/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import ReportsCard from '../../components/ReportsCard';
import champyAdsResults from '../../assets/reporting_views/ssr_champy_ads_results.png';
import slackAdsResults from '../../assets/reporting_views/ads_results.png';

function SSRAdsLanding() {
  useEffect(() => {
    document.title = 'MissionPortal | SSR Ads';
  }, []);
  const { client } = useParams();

  return (
    <div className="App">
      <header className="App-header">
        <h2>Strategic Services - Ads</h2>
      </header>
      <h3>
        Ads revenue
      </h3>
      <Box sx={{ flex: 1 }}>
        <div className="reporting-row-container">
          <ReportsCard
            image={champyAdsResults}
            imageAlt="Champy ads results report"
            title="Champy Ads Results"
            description="A filterable table of the Champy ads reporting with the flexibility to filter by date, channel, channel category, etc"
            bullets={[]}
            route={`/client/${client}/Strategy/Ads/champy_ads_results`}
            buttonText="View Report"
          />
          <ReportsCard
            image={slackAdsResults}
            imageAlt="Re-run Ads Reports"
            title="Re-run Ads Reports"
            description="Re-run ads results report or ROAS report that will be delivered through Slack. Breakdown for ads results can be chosen to include:"
            bullets={['Daily result', 'Weekly results', 'Monthly results', 'Custom start and end date']}
            route={`/client/${client}/Strategy/Ads/run_ads_results`}
            buttonText="Re-run Report"
          />
        </div>
      </Box>
    </div>
  );
}

export default SSRAdsLanding;
