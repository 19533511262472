import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs } from '@mui/material';
import { useParams } from 'react-router-dom';

import FallbackOnError from '../components/FallbackOnError';
import {
  getLocalStorageTablePref,
  logReactErrBoundaryError,
  updateTablePreferenceLocalStorage,
  tableColumnPreferenceLoader,
} from '../utils';
import logger from '../utils/logger';
import TabPanel from '../components/TabPanel';
import AdvancedSearch from '../components/AdvancedSearch';
import {
  insightsCohortColumns, insightsNonCohortColumns, insightsReacCohortColumns,
} from '../utils/schemaInfo';
import {
  createInsightsCohortRows, createInsightsNonCohortRows, createInsightsReacCohortRows,
} from '../utils/tableHelpers';

const defaultSortModel = { field: 'updated_dt', direction: 'desc' };

function Insights(props) {
  const { pageTitle, queries, heading } = props;
  const [pageState, setPageState] = useState({
    isLoading: true,
    page: 0, // mui pages are 0 indexed
    pageSize: 50,
    data: [],
    total: 0,
  });
  const { client } = useParams();

  useEffect(() => {
    document.title = `MissionPortal | ${pageTitle}`;
  }, []);

  const labelsAndQueries = {
    'By JIRA Ticket': {
      query: queries[0],
      isCohort: false,
    },
    'By JIRA Ticket/Cohort': {
      query: queries[1],
      isCohort: true,
    },
  };

  const labels = Object.keys(labelsAndQueries);
  const cohortDescription = `${heading} > JIRA AAI Aggregated Results`;
  const [currentTab, setCurrentTab] = useState(0);
  const [currTabName, setCurrTabName] = useState(Object.keys(labelsAndQueries)[0]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [filterModel, setFilterModel] = useState({ items: [] });

  function getDefaultColumns(tabName) {
    /**
     * get columns to be sent to the AdvancedSearch component based on the tab selection.
     * @param {String} tabName the current tab name
     *
     * @returns {Array} the columns that should display for the current cat/sub tab selection
     */
    if (heading === 'Reactivation' && tabName === 'By JIRA Ticket/Cohort') {
      return insightsReacCohortColumns;
    }
    return labelsAndQueries[tabName].isCohort ? insightsCohortColumns : insightsNonCohortColumns;
  }

  const [currCols, setCurrCols] = useState(getDefaultColumns(currTabName) || []);

  const updateUserPreferences = () => {
    const currTablePrefix = `insights-${labelsAndQueries[currTabName].query}`;
    const listFilters = getLocalStorageTablePref(currTablePrefix, 'filter');
    if (listFilters) {
      setFilterModel(listFilters);
    }

    const defaultCols = getDefaultColumns(currTabName);
    // this usage differs from other tables because Insights doesn't store user preference for
    // column order in react state
    tableColumnPreferenceLoader(currTablePrefix, 'column', defaultCols);

    const listColumnVisibility = getLocalStorageTablePref(currTablePrefix, 'column-visibility');
    if (!listColumnVisibility) {
      const defCols = getDefaultColumns(currTabName);

      const fieldList = defCols.map((col) => col.field);
      const initColumnVisibilityModel = Object.fromEntries(fieldList.map((k) => [k, true]));

      updateTablePreferenceLocalStorage(currTablePrefix, 'column-visibility', initColumnVisibilityModel);
    } else {
      setColumnVisibilityModel(listColumnVisibility);
    }
  };

  const handleChange = (event, newValue) => {
    /**
     * handle tab change event, updates the current tab and sets the current tab name
     * @param {Event} event the tab change event
     * @param {Number} newValue the new tab index
     */
    setCurrentTab(newValue);
    const newTabName = Object.keys(labelsAndQueries)[newValue];
    setCurrTabName(newTabName);
    setCurrCols(getDefaultColumns(newTabName));
  };

  const createRowsFuncSelector = (tabName) => {
    /**
     * select create rows function to be sent to AdvancedSearch component based on
     * current tab/subtab selection.
     * @param {String} tabName the current sub tab name, from state
     *
     * @returns {Function} the correct create rows function for the current table
     */
    if (heading === 'Reactivation' && tabName === 'By JIRA Ticket/Cohort') {
      return createInsightsReacCohortRows;
    }
    const cohort = labelsAndQueries[tabName].isCohort;
    return cohort === true ? createInsightsCohortRows : createInsightsNonCohortRows;
  };

  useEffect(() => {
    updateUserPreferences();
  }, [currTabName, currentTab]);

  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
      onReset={(details) => {
        logger.info('details', details);
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      <Box sx={{ width: '100%' }}>
        <header className="App-header">
          <h2>
            {cohortDescription}
          </h2>
        </header>
        <div>
          <Tabs
            indicatorColor="primary"
            value={currentTab}
            onChange={handleChange}
          >
            {labels.map((label) => (<Tab label={label} key={label} />))}
          </Tabs>
          <TabPanel value={currentTab} index={currentTab}>
            <AdvancedSearch
              pinnedColumns={{ pinnedColumns: { left: ['updated_dt', 'client_type', 'client', 'jira_ticket_num', 'first_delivery_date'] } }}
              columns={currCols}
              getDefaultColumns={() => getDefaultColumns(currTabName)}
              setOrderedColumns={setCurrCols}
              sortModel={defaultSortModel}
              columnVisibilityModel={columnVisibilityModel}
              setColumnVisibilityModel={setColumnVisibilityModel}
              filterModel={filterModel}
              setFilterModel={setFilterModel}
              pageState={pageState}
              updatePageState={setPageState}
              queryName={labelsAndQueries[currTabName].query}
              createRows={createRowsFuncSelector(currTabName)}
              tablePrefix={`insights-${labelsAndQueries[currTabName].query}`}
              defaultFilter={[{ client: { eq: client } }]}
              excludeFields={['client']}
            />
          </TabPanel>
        </div>
      </Box>
    </ErrorBoundary>
  );
}

export default Insights;

Insights.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  queries: PropTypes.arrayOf(PropTypes.string).isRequired,
  heading: PropTypes.string.isRequired,
};
