import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '../../components/TabPanel';

function ViewReport(props) {
  const {
    tabInfo, token, heading, subheading,
  } = props;
  const { urls, labels, filterable } = tabInfo;
  const { client } = useParams();

  const [value, setValue] = useState(0);
  const [tableauUrl, setTableauUrl] = useState(urls[0]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTableauUrl(urls[newValue]);
  };

  useEffect(() => {
    document.title = `MissionPortal | ${subheading}`;
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h2>
          {`${heading} > ${subheading}`}
        </h2>
      </header>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          indicatorColor="primary"
          value={value}
          onChange={handleChange}
        >
          {labels.map((label) => (<Tab label={label} key={label} />))}
        </Tabs>
      </Box>
      <div>
        <TabPanel value={value} index={value}>
          <tableau-viz
            id="tableau-viz"
            src={tableauUrl}
            width="100%"
            height="840"
            hide-tabs
            token={token}
            toolbar="top"
          >
            {filterable[value] && <viz-filter field="Client" value={client} />}
          </tableau-viz>
        </TabPanel>
      </div>
    </div>
  );
}

export default ViewReport;

ViewReport.propTypes = {
  tabInfo: PropTypes.oneOfType([PropTypes.object]).isRequired,
  token: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
};
