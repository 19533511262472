import { useState, useEffect, useContext } from 'react';
import * as React from 'react';
import {
  withAuthenticationRequired,
  useAuth0,
} from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import Loading from '../components/Loading';
import AppContext from '../context';
import Logout from './Logout';

function ProtectedRoute({ component, ...props }) {
  const { auth0Tok } = useContext(AppContext);
  const { isLoading } = useAuth0();
  const [isValidToken, setValidToken] = useState(null);

  useEffect(() => {
    function checkValidity() {
      if (auth0Tok) {
        setValidToken(auth0Tok.exp * 1000 > (new Date()).getTime());
      }
    }
    checkValidity();
  });

  const Component = withAuthenticationRequired(component, {
    // eslint-disable-next-line react/no-unstable-nested-components
    onRedirecting: () => <Loading />,
  });

  return (
    isValidToken === false && auth0Tok && !isLoading
  ) ? <Logout />
    : <Component body={props.body} />;
}

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  body: PropTypes.element.isRequired,
};
