import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import PropTypes from 'prop-types';

/*
Summary of props
- editRowData
- label: string
- options: [string]
- setFunction
- id
- validateFunction: TODO in the future it would probably be useful to pass in custom validation
  rules as a function
*/
function MPAutocomplete(props) {
  const {
    editRowData, label, options, setFunction, id, field, setSourceSearch,
  } = props;
  // tracks value for the select list part of autocomplete (2 part component)
  const [selectedOption, setSelectedOption] = useState(editRowData[field] || '');
  // tracks value for the text input part of autocomplete (2 part component)
  const [inputValue, setInputValue] = useState('');

  // when input value changes, set state in parent component to trigger search
  // we are only using this autocomplete for the codes edit dialog
  // will need to refactor this if we want to use this component elsewhere
  useEffect(() => {
    setSourceSearch(inputValue);
  }, [inputValue]);

  // want to replace this with props validateFunction
  // but not sure how to check that a valid option is selected vs the default ('select one')
  const selectedOptionIsValid = (value) => value && options && options.length
    && options.indexOf(value) > -1;

  /* handle drop down change */
  // TODO refactor to make validation a prop (function passed in)
  function handleSourceSelectChange(value) {
    // validation logic (in case triggered by input downKey event)
    let isValueValid = true;
    isValueValid = selectedOptionIsValid(value);
    if (!isValueValid) {
      Error('You need to select an option from the list.');
    }
  }

  return (
    <div>
      <InputLabel variant="standard" htmlFor="uncontrolled-native">
        {label}
        :
      </InputLabel>
      <Autocomplete
        freeSolo // helps avoid warnings since we are using an api call to populate options
        options={options}
        sx={{ width: 300 }}
        value={selectedOption}
        onChange={(event, newValue) => {
          event.stopPropagation();
          handleSourceSelectChange(id, newValue);
          setSelectedOption(newValue);
          setFunction(field, newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onKeyDown={(event, newInputValue) => {
          if (event.key === 'Enter') {
            handleSourceSelectChange(id, newInputValue);
          }
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={(params) => <TextField {...params} />}
        isOptionEqualToValue={(option, value) => value === '' || option === value}
        disableClearable
      />
    </div>
  );
}

export default MPAutocomplete;

MPAutocomplete.propTypes = {
  client: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  setFunction: PropTypes.func,
  editRowData: PropTypes.oneOfType([PropTypes.object]),
  options: PropTypes.oneOfType([PropTypes.array]),
  field: PropTypes.string,
  setSourceSearch: PropTypes.func.isRequired,
};
