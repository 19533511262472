import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { Button } from '@mui/material';
import logger from '../utils/logger';
import FallbackOnError from '../components/FallbackOnError';
import { logReactErrBoundaryError } from '../utils';

/**
 * Page for user to return to home screen if page view does not exist
 * @returns "Not found" page view
 */
function NotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'MissionPortal | Error 404';
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
      onReset={(details) => {
        logger.info('details', details);
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      <div>
        <div id="notfound-info">
          <p>Sorry! This page does not exist.</p>

          <Button
            size="small"
            id="404-home"
            variant="contained"
            onClick={() => navigate('/')}
          >
            Take me home
          </Button>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default NotFound;
