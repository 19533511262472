import React from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import ClientForm from './ClientForm';

class ClientTextarea extends ClientForm {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      isValid: true,
      stateHelperText: '',
    };
    this.checkValidity = this.checkValidity.bind(this);
  }

  checkValidity(entry) {
    /**
     * This function checks the validity of the input and changes the helper text
     * and validity state accordingly
     * @param {Object} entry - The event object
     * @return {void}
     */
    if (this.state.attribute === 'pause_data') {
      // check if the entry to valid JSON
      try {
        if (entry.target.value === '') {
          this.setState({ ...this.state, stateHelperText: 'Please enter a valid JSON object.' });
          return;
        }
        const parsedEntry = JSON.parse(entry.target.value);
        // check values for each key and make sure they are 'forever' or a date formatted yyyy-mm-dd
        if (typeof parsedEntry !== 'object') {
          throw new Error('The input must be a valid JSON object (wrapped in {}).');
        }
        Object.values(parsedEntry).forEach((value) => {
          if (value !== 'forever') {
            if (!value.match(/^\d{4}-\d{2}-\d{2}$/)) {
              throw new Error('Please enter a date in format YYYY-MM-DD, or the string "forever" for all values.');
            }
          }
        });
        this.setState({ ...this.state, stateHelperText: 'Entry is valid.', isValid: true });
        this.updateClientState(entry);
        this.updateFormValidity(true);
      } catch (e) {
        if (e.message === 'Please enter a date in format YYYY-MM-DD, or the string "forever" for all values.') {
          this.setState({ ...this.state, isValid: false, stateHelperText: e.message });
        } else if (e.message === 'The input must be a valid JSON object (wrapped in {}).') {
          this.setState({ ...this.state, isValid: false, stateHelperText: e.message });
        } else {
          this.setState({ ...this.state, isValid: false, stateHelperText: 'Please enter a valid JSON object.' });
        }
        this.updateFormValidity(false);
      }
    } else {
      // will handle if input is a string
      try {
        this.setState({ ...this.state, stateHelperText: 'Entry is valid.', isValid: true });
        this.updateClientState(entry);
        this.updateFormValidity(true);
      } catch (e) {
        this.setState({ ...this.state, isValid: false, stateHelperText: e.message });
      }
    }
  }

  render() {
    return (
      <div key={this.state.attribute}>
        <InputLabel
          disabled={this.state.isDisabled}
          variant="standard"
          htmlFor="uncontrolled-native"
          required={this.state.required}
        >
          {this.state.label}
        </InputLabel>
        <TextField
          error={!this.state.isValid}
          onChange={this.checkValidity}
          fullWidth
          disabled={this.props.isDisabled || this.state.isDisabled}
          id={this.state.attribute}
          defaultValue={this.state.data}
          name={this.state.attribute}
          style={{ minWidth: 200, maxWidth: 450 }}
          size="small"
          inputProps={{ 'data-testid': this.state.testingId }}
          type={this.state.type || 'text'}
          helperText={this.state.stateHelperText}
          multiline
          rows={4}
        />
      </div>
    );
  }
}

export default ClientTextarea;
