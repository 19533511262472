/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import ReportsCard from '../../components/ReportsCard';
// card images
import reacInventory from '../../assets/reporting_views/tdc_reac_inventory.png';
import reacPerfomance from '../../assets/reporting_views/tdc_reac_performance.png';
import reacResults from '../../assets/reporting_views/tdc_reac_results.png';

function TDCReactivationLanding() {
  /**
   * Component used for displaying various TDC Reactivation report summaries with links to the full reports
   */
  useEffect(() => {
    document.title = 'MissionPortal | TDC Email Reactivation';
  }, []);
  const { client } = useParams();

  return (
    <div className="App">
      <header className="App-header">
        <h2>The Digital Co-Op Email Reactivation</h2>
      </header>
      <h5>
        Identify people on your client&#39;s list who are inactive and are likely to be responsive to mailing again. Learn more about Digital Co-Op Email Reactivation in the
        <a target="_blank" href="https://missionwired.atlassian.net/wiki/spaces/AAI/overview" rel="noreferrer"> Digital Co-Op Confluence Space.</a>
      </h5>

      <Box sx={{ flexGrow: 1 }}>
        <h3>Pre-Delivery</h3>
        <div className="reporting-row-container">
          <ReportsCard
            image={reacInventory}
            imageAlt="Reactivation Inventory view"
            title="Reactivation Inventory"
            description="Reactivation emails available for each client, given certain criteria on days since activity, recency, and donor status."
            bullets={[]}
            route={`/client/${client}/Co-Op/Reactivation/reactivation_inventory`}
            buttonText="View Report"
          />
        </div>
        <h3>Post Delivery Upload</h3>
        <div className="reporting-row-container">
          <ReportsCard
            image={reacPerfomance}
            imageAlt="Reactivation Performance view"
            title="Reactivation - Performance"
            description="Client-ready visualizations of TDC Reactivation performance"
            bullets={['Reactivation Results - information on cohort size, cost, and performance', 'Reactivation Revenue/Investment - quick look at revenue and cost of each delivery']}
            route={`/client/${client}/Co-Op/Reactivation/reactivation_performance`}
            buttonText="View Report"
          />
          <ReportsCard
            image={reacResults}
            imageAlt="JIRA TDC Reactivation Aggregate Results view"
            title="JIRA TDC Reactivation Aggregate Results"
            description="Performance of Digital Co-Op Reactivation deliveries to date"
            bullets={['Tab 1 groups all names delivered under the same JIRA ticket', 'Tab 2 groups all names delivered per cohort per JIRA ticket (if there were 3 cohorts provided in one JIRA ticket, they would be displayed on 3 rows)']}
            route={`/client/${client}/Co-Op/Reactivation/aggregate_results`}
            buttonText="View Report"
          />
        </div>
      </Box>

    </div>
  );
}

export default TDCReactivationLanding;
