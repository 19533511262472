function formatDate(timeStampInfo) {
  // format timestamp date
  // handles when timeStampInfo is an object and we need to access updatedAt
  // also handles when we receive timeStampInfo as just a string with a timestamp
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'America/New_York',
  };

  try {
    let datetime;
    if (typeof timeStampInfo === 'string') {
      datetime = timeStampInfo;
    } else {
      datetime = timeStampInfo.updatedAt;
    }
    if (!datetime) {
      return null;
    }
    datetime = new Date(datetime);
    const formatteddatetime = `${datetime.toLocaleDateString(
      'en-US',
      options,
    )} EST`;
    return formatteddatetime;
  } catch (e) {
    return null;
  }
}

function currencyFormatter(currency) {
  /**
   * Format plain number to USD currency
   * @param {number} currency - number to format
   * @returns {string} - formatted currency
   */
  try {
    if (currency === 0) {
      return '$0.00';
    }
    if (!currency) {
      return currency;
    }
    const formatter = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(currency);
  } catch (err) {
    return currency;
  }
}

export {
  formatDate,
  currencyFormatter,
};
