import React, { useContext } from 'react';
import { Star, StarBorder } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { GridActionsCellItem } from '@mui/x-data-grid';
import theme from './themes/Theme';
import AppContext from '../context';

function FavButton({
  id, data = [], toggleFav,
}) {
  const { favClientsList } = useContext(AppContext);
  let index = 0;
  let isFav = false;
  while (index < data.length) {
    if (data[index].id === id) {
      const curr = data[index].code;
      if (favClientsList.includes(curr)) {
        isFav = true;
      }
      index = data.length + 1;
    }
    index += 1;
  }

  if (isFav) {
    return (
      <GridActionsCellItem
        icon={<Star sx={{ color: theme.palette.primary.main }} />}
        label="Fav"
        onClick={(ev) => toggleFav(ev, id)}
        data-testid="fav-icon"
      />
    );
  }
  return (
    <GridActionsCellItem
      icon={<StarBorder />}
      label="Fav"
      onClick={(ev) => toggleFav(ev, id)}
      data-testid="unfav-icon"
    />
  );
}

export default FavButton;

FavButton.propTypes = {
  id: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array]),
  toggleFav: PropTypes.func,
};
