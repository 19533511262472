import React from 'react';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import PropTypes from 'prop-types';
import { GridActionsCellItem } from '@mui/x-data-grid';

function SettingsButton({ id }) {
  /**
     * Settings button for client list
     * @param {string} id - client id
     * @returns {JSX.Element}
     */
  const navigate = useNavigate();

  return (
    <GridActionsCellItem
      icon={<SettingsIcon />}
      label="Settings"
      onClick={() => navigate(`/client/${id}/settings`, {
        replace: false,
        state: { client: id },
      })}
    />
  );
}

export default SettingsButton;

SettingsButton.propTypes = {
  id: PropTypes.string,
};
