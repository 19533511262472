/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import {
  CustomDateRangePicker,
  CustomDatePicker,
  CustomDateTimePicker,
  CustomDateTimeRangePicker,
} from './DateAndTimePickers';
import { operations, opLabels } from '../utils/schemaInfo';

/**
 * Component for selecting a column.
 * @param {string} initCol - initial selected column
 * @param {Array} allCols - list of all available columns
 * @param {function} setSelectedCol - function to set the selected column
 */
function ColumnSelector({ initCol, allCols, setSelectedCol }) {
  const alphabetizedCols = allCols.sort((a, b) => a.headerName.localeCompare(b.headerName));
  const [selectedValue, setSelectedValue] = useState(
    alphabetizedCols.find((col) => col.field === initCol) || alphabetizedCols[0],
  );

  const handleColNameChange = (event, val) => {
    if (val) {
      setSelectedValue(val);
      setSelectedCol(val.field);
    }
  };

  return (
    <Autocomplete
      options={alphabetizedCols}
      value={selectedValue}
      getOptionLabel={(option) => option.headerName}
      onChange={(event, val) => handleColNameChange(event, val)}
            // eslint-disable-next-line react/jsx-props-no-spreading
      renderInput={(params) => <TextField {...params} label="Column" />}
      key="column-selector"
      sx={{ width: 300 }}
    />
  );
}

ColumnSelector.propTypes = {
  initCol: PropTypes.string.isRequired,
  allCols: PropTypes.instanceOf(Array).isRequired,
  setSelectedCol: PropTypes.func.isRequired,
};

/**
 * Component for selecting an operation (eq, ne, exists, >, <, etc)
 * @param {Array} availableOps - list of available operations
 * @param {string} selectedOp - current selected operation
 * @param {function} setSelectedOp - function to set the selected operation
 * @param {string} criteriaType - type of criteria (dropdown, stringtext, etc)
 */
function OperationSelector({
  availableOps, selectedOp, setSelectedOp, criteriaType,
}) {
  const handleOpChange = (event) => {
    setSelectedOp(event.target.value);
  };

  // for time we have a different mapping of operations to labels
  const availableTimeOpsLabels = { gte: 'after', lte: 'before', between: 'between' };
  const opsLabelsBasedOnType = (criteriaType === 'awsdate' || criteriaType === 'awsdatetime') ? availableTimeOpsLabels : opLabels;

  return (
    <Select
      data-testid="operation-selector"
      onChange={handleOpChange}
      value={selectedOp}
      sx={{ width: 100 }}
    >
      {availableOps.map((op) => (
        <MenuItem value={op} key={op}>
          {opsLabelsBasedOnType[op]}
        </MenuItem>
      ))}
    </Select>
  );
}

OperationSelector.propTypes = {
  availableOps: PropTypes.instanceOf(Array).isRequired,
  selectedOp: PropTypes.string.isRequired,
  setSelectedOp: PropTypes.func.isRequired,
  criteriaType: PropTypes.string.isRequired,
};

/**
 * Component for selecting the criteria - the last dropdown (col operation criteria)
 * @param {string} criteriaType - type of criteria (dropdown, stringtext, etc)
 * @param {string|Array} criteria - selected criteria
 * @param {function} setCriteria - function to set the criteria
 * @param {Array} criteriaOptions - list of criteria options (for dropdowns)
 * @param {string} selectedOp - current selected operation
 */
function CriteriaInput({
  criteriaType,
  criteria,
  setCriteria,
  criteriaOptions = [],
  selectedOp,
}) {
  const renderCriteriaInput = () => {
    switch (criteriaType) {
      case 'dropdown':
        return (
          <Select
            label="Criteria"
            value={criteria}
            onChange={(event) => setCriteria(event.target.value)}
            sx={{ width: 275 }}
          >
            {criteriaOptions.map((option) => (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            ))}
            {' '}
          </Select>
        );
      case 'stringtext':
        return (
          <TextField
            label="Criteria"
            value={criteria}
            onChange={(event) => setCriteria(event.target.value)}
            sx={{ width: 275 }}
          />
        );
      case 'inttext':
        return (
          <TextField
            label="Integer"
            error={!/^\d+$/.test(criteria)}
            helperText={/^\d+$/.test(criteria) ? '' : 'Must be an integer'}
            value={criteria}
            onChange={(event) => setCriteria(event.target.value)}
            sx={{ width: 275 }}
          />
        );
      case 'floattext':
        return (
          <TextField
            label="Number"
            error={!Number.isInteger(parseFloat(criteria))}
            helperText={Number.isInteger(parseFloat(criteria)) ? '' : 'Must be a number'}
            value={criteria}
            onChange={(event) => setCriteria(event.target.value)}
            sx={{ width: 275 }}
          />
        );
      case 'freeSolo':
        return (
          <Autocomplete
            freeSolo
            options={criteriaOptions}
            value={criteria}
            onChange={(event, newValue) => {
              setCriteria(newValue);
            }}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="Criteria"
                sx={{ width: 275 }}
              />
            )}
          />
        );
      case 'awsdate':
        if (selectedOp === 'between') {
          return (
            <CustomDateRangePicker setCriteria={setCriteria} criteria={criteria} />
          );
        }

        return (
          <CustomDatePicker setCriteria={setCriteria} criteria={criteria} />
        );
      case 'awsdatetime':
        if (selectedOp === 'between') {
          return (
            <CustomDateTimeRangePicker setCriteria={setCriteria} criteria={criteria} />
          );
        }

        return (
          <CustomDateTimePicker setCriteria={setCriteria} criteria={criteria} />
        );
      default:
        return null;
    }
  };

  return renderCriteriaInput();
}

CriteriaInput.propTypes = {
  criteriaType: PropTypes.string.isRequired,
  criteria: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  setCriteria: PropTypes.func.isRequired,
  criteriaOptions: PropTypes.instanceOf(Array),
  selectedOp: PropTypes.string,
};

/**
 * Gets the list of operations based on the column
 * @param {Array} allCols - list of all available columns
 * @param {string} colField - current field of the selected column
 * @returns {Array} - list of available operations given the current field
 */
const getOperationsGivenColField = (allCols, colField) => {
  const colsByField = allCols.reduce((acc, col) => ({ ...acc, [col.field]: col }), {});

  const format = colsByField[colField].format.toLowerCase();
  if (format === 'string') {
    return Object.keys(operations.stringOps);
  }
  if (format === 'bool' || format === 'boolean' || format === 'dropdown') {
    return Object.keys(operations.boolOps);
  }
  if (format === 'int' || format === 'float') {
    return Object.keys(operations.numberOps);
  }
  if (format === 'date' || format === 'datetime' || format === 'awsdate' || format === 'awsdatetime') {
    return Object.keys(operations.awsdtOps);
  }
  if (format === 'options') {
    return Object.keys(operations.boolOps);
  }
  return [];
};

/**
 * Gets criteria information based on the operation and column field.
 * @param {string} op - selected operation
 * @param {string} colField - current field of the selected column
 * @param {string|Array} criteria - selected criteria (can be string or an
 *                array like ['true', 'false'])
 * @param {string} criteriaType - type of criteria (dropdown, stringtext, etc)
 * @param {Array} allCols - list of all available columns
 * @returns {Object} - Object w/ new criteria information
 *
 * The logic here is:
 * 1. If the op = 'exists' or the format is bool, then criteria must be true/false (in a dropdown)
 * 2. If the format = 'options', we have a list of options the user can choose from
 *      so have a dropdown with them
 * 3. If the format is 'awsdate' or 'awsdatetime', then we want to use a date picker
 * 4. If the format = 'int' or 'float', then use a text input that'll flag if it's not a number
 * 5. Otherwise, as a catch-all we want to use a text input
 *
 */
const getCriteriaInfoGivenOpAndColField = (
  op,
  colField,
  criteria,
  criteriaType,
  allCols,
) => {
  try {
    // create a map of columns by field name to make it easier to access
    const colsByField = allCols.reduce((acc, col) => ({ ...acc, [col.field]: col }), {});
    const format = colsByField[colField].format.toLowerCase();

    // if op is exists or format is bool, then dropdown with true/false
    if (op === 'exists' || format === 'bool' || format === 'boolean') {
      const newCriteria = (criteria === 'true' || criteria === 'false') ? criteria : 'true';
      return {
        newCriteriaType: 'dropdown',
        newCriteriaOptions: ['true', 'false'],
        newCriteria,
      };
    } if (format === 'options') {
      // if format is options then dropdown with options
      const options = colsByField[colField].options || ['none'];
      const newCriteria = options.includes(criteria) ? criteria : options[0];
      return {
        newCriteriaType: 'freeSolo',
        newCriteriaOptions: options,
        newCriteria,
      };
    } if (format === 'awsdate' || format === 'awsdatetime') {
      // if format is awsdate or awsdatetime, then use date picker
      const now = dayjs(new Date());
      const nowDate = dayjs(now).format('YYYY-MM-DD');
      const nowDateTime = dayjs(now).format('YYYY-MM-DDTHH:mm:ss');

      let newCriteria = now;
      // if it's a between operation, then we want to use a range picker
      if (op === 'between') {
        // this is super ugly, but this is formatting the criteria to be in the correct format
        // so that the date picker can try and parse it
        if (format === 'awsdate' && criteria.length === 2 && Array.isArray(criteria)) {
          const start = dayjs(criteria[0]).isValid() ? dayjs(criteria[0]).format('YYYY-MM-DD') : nowDate;
          const end = dayjs(criteria[1]).isValid() ? dayjs(criteria[1]).format('YYYY-MM-DD') : nowDate;
          newCriteria = [start, end];
        } else if (format === 'awsdatetime' && criteria.length === 2 && Array.isArray(criteria)) {
          // this is also super ugly, but this is formatting the criteria to be the correct format
          const start = dayjs(criteria[0]).isValid() ? dayjs(criteria[0]).format('YYYY-MM-DDTHH:mm:ss') : nowDateTime;
          const end = dayjs(criteria[1]).isValid() ? dayjs(criteria[1]).format('YYYY-MM-DDTHH:mm:ss') : nowDateTime;
          newCriteria = [start, end];
        } else {
          newCriteria = [now, now];
        }
      } else {
        // if it's not a between operation, then we want to use a single date picker
        try {
          // format criteria so it's in the right format for the date/dt picker
          if (format === 'awsdate') {
            newCriteria = dayjs(criteria).isValid() ? dayjs(criteria).format('YYYY-MM-DD') : now;
          } else if (format === 'awsdatetime') {
            newCriteria = dayjs(criteria).isValid() ? dayjs(criteria).format('YYYY-MM-DDTHH:mm:ss') : now;
          }
        } catch (err) {
          newCriteria = now;
        }
      }

      return {
        newCriteriaType: format,
        newCriteriaOptions: [],
        newCriteria,
      };
    }

    // if format is a string, int, or float we want a textbox style input
    let newType = 'stringtext';
    if (format === 'int') {
      newType = 'inttext';
    } else if (format === 'float') {
      newType = 'floattext';
    }

    let newCriteria = criteria;
    // if the user changes the column and the criteria is no longer valid it's gotta be cleared
    if (criteriaType !== null && criteriaType !== newType) {
      newCriteria = '';
    }

    return {
      newCriteriaType: newType,
      newCriteriaOptions: [],
      newCriteria,
    };
  } catch (err) {
    // if there's an error, just default to a stringtext input since the user can type anything
    const newType = 'stringtext';
    const newCriteria = criteriaType !== newType ? '' : criteria;

    return {
      newCriteriaType: 'stringtext',
      newCriteriaOptions: [],
      newCriteria,
    };
  }
};

/**
 * Component for managing search criteria (col, operation, criteria)
 * @param {Array} allCols - list of all available columns
 * @param {number} currIndex - current index of the search criteria (used for updating the search)
 * @param {Object} currValues - current values of the search criteria (col, op, criteria)
 * @param {function} setSearch - function to set the search criteria
 * @param {boolean} isOr - indicates if it's an 'or' operation (child component)
 * @param {string} fixedCol - required if it's an 'or' operation, this is the parent's col
 * @param {number} parentIndex - required if it's an 'or' operation, this is the parent's index
 * @param {number} id - unique ID for the search criteria
 * @param {boolean} disabled - indicates if it's disabled/editable by the user
 * @param {Object} fixedValues - required if the component is disabled - contains col, op, criteria
 */
function SearchCriteria({
  allCols,
  currIndex,
  currValues,
  setSearch,
  isOr = false,
  fixedCol = null, // used for 'or' searches - it must have same col as parent
  parentIndex = null,
  id,
  disabled = false,
  fixedValues = {},
}) {
  const initCol = fixedCol || fixedValues.col || currValues.col || allCols[0].field;
  const initOps = getOperationsGivenColField(allCols, initCol);
  const [availableOps, setAvailableOps] = useState(initOps);
  const initOp = (() => {
    if (fixedValues.op && availableOps.includes(fixedValues.op)) {
      return fixedValues.op;
    }
    if (currValues.op !== '' && availableOps.includes(currValues.op)) {
      return currValues.op;
    }
    return availableOps[0];
  })();
  const initCriteria = fixedValues.criteria || currValues.criteria || '';
  const initVals = getCriteriaInfoGivenOpAndColField(initOp, initCol, initCriteria, null, allCols);

  const [selectedCol, setSelectedCol] = useState(initCol);
  const [selectedOp, setSelectedOp] = useState(initOp);

  const [criteria, setCriteria] = useState(initVals.newCriteria);
  const [criteriaType, setCriteriaType] = useState(initVals.newCriteriaType);
  const [criteriaOptions, setCriteriaOptions] = useState(initVals.newCriteriaOptions);

  // when the col changes, make sure the op is still valid and criteria input type is correct
  // when the op changes, make sure the criteria input type is correct
  useEffect(() => {
    const newValidOps = getOperationsGivenColField(allCols, selectedCol);
    setAvailableOps(newValidOps);
    const newOp = newValidOps.includes(selectedOp) ? selectedOp : newValidOps[0];
    setSelectedOp(newOp);

    const { newCriteria, newCriteriaType, newCriteriaOptions } = getCriteriaInfoGivenOpAndColField(
      selectedOp,
      selectedCol,
      criteria,
      criteriaType,
      allCols,
    );

    setCriteria(newCriteria);
    setCriteriaType(newCriteriaType);
    setCriteriaOptions(newCriteriaOptions);
  }, [selectedCol, selectedOp]);

  useEffect(() => {
    const updateSearch = (prev) => {
      const newSearch = [...prev];
      newSearch[currIndex].children = [];
      return newSearch;
    };

    if (parentIndex == null) {
      setSearch(updateSearch);
    }
  }, [selectedCol]);

  // if anything changes, update the search
  useEffect(() => {
    setSearch((prev) => {
      const newSearch = [...prev];

      const searchItem = {
        id,
        col: selectedCol,
        op: selectedOp,
        criteria,
      };

      if (isOr) {
      // This is a child, update parent
        newSearch[parentIndex].children[currIndex] = searchItem;
      } else {
      // This is not a child
        newSearch[currIndex] = { ...newSearch[currIndex], ...searchItem };
      }

      return newSearch;
    });
  }, [selectedCol, selectedOp, criteria]);

  const handleDelete = (index) => {
    if (isOr) {
      setSearch((prev) => {
        const newSearch = [...prev];
        newSearch[parentIndex].children.splice(index, 1);
        return newSearch;
      });
    } else {
      setSearch((prev) => {
        const newSearch = [...prev];
        newSearch.splice(index, 1);
        return newSearch;
      });
    }
  };

  const colsByField = allCols.reduce((acc, col) => ({ ...acc, [col.field]: col }), {});

  return (
    <div data-testid="search-criteria">
      <div key={currIndex} className="search-criteria">
        {disabled && (
        <>
          <Select value={fixedValues.col} sx={{ width: 300 }} disabled>
            <MenuItem value={fixedValues.col}>{colsByField[fixedValues.col].headerName}</MenuItem>
          </Select>
          <Select value={fixedValues.op} sx={{ width: 100 }} disabled>
            <MenuItem value={fixedValues.op}>{opLabels[fixedValues.op]}</MenuItem>
          </Select>
          <TextField label="Criteria" value={fixedValues.criteria} sx={{ width: 275 }} disabled />
        </>
        )}
        {!disabled && (
        <>
          {!isOr && (
          <ColumnSelector
            initCol={initCol}
            allCols={allCols}
            setSelectedCol={setSelectedCol}
          />
          )}
          {isOr && (
          <Button
            variant="text"
            size="small"
            disabled
            style={{ color: 'black', width: '300px' }}
          >
            OR
          </Button>
          )}
          <div>
            <OperationSelector
              availableOps={availableOps}
              selectedOp={selectedOp}
              setSelectedOp={setSelectedOp}
              criteriaType={criteriaType}
            />
          </div>
          <CriteriaInput
            criteriaType={criteriaType}
            criteria={criteria}
            setCriteria={setCriteria}
            criteriaOptions={criteriaOptions}
            selectedOp={selectedOp}
          />
        </>
        )}
        <IconButton aria-label="delete" onClick={() => handleDelete(currIndex)}>
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
}

SearchCriteria.propTypes = {
  allCols: PropTypes.instanceOf(Array).isRequired,
  currIndex: PropTypes.number.isRequired,
  currValues: PropTypes.instanceOf(Object).isRequired,
  setSearch: PropTypes.func.isRequired,
  isOr: PropTypes.bool, // is a or operation (it's a child)
  fixedCol: PropTypes.string, // required if or
  parentIndex: PropTypes.number, // required if or
  id: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  fixedValues: PropTypes.instanceOf(Object), // required if disabled
};

export {
  ColumnSelector,
  OperationSelector,
  CriteriaInput,
  getCriteriaInfoGivenOpAndColField,
};

export default SearchCriteria;
