import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import FallbackOnError from '../components/FallbackOnError';
import logger from '../utils/logger';
import { logReactErrBoundaryError } from '../utils';

/**
 * Default page for all unauthorized requests.
 * @returns Logout prompt
 */
function Unauthorized() {
  useEffect(() => {
    document.title = 'MissionPortal | Unauthorized';
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
      onReset={(details) => {
        logger.info('details', details);
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      <div>
        <div id="unauthorized">
          <p>You are not authorized to view this page.</p>
          <p>
            Your session may have expired, try
            {' '}
            <Link to="/logout">logging in again</Link>
            .
          </p>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default Unauthorized;
