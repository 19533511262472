import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  Tooltip,
} from '@mui/material';

function CodesCard(props) {
  /**
   * This component is used to render the code and revenue cards on the client landing page
   *
   * @param {Object} props - The props object
   * @param {number} props.count - Count of codes that are ungrouped
   * @param {string} props.revenue - Revenue used in Raised Today or Raised This Month cards
   * @param {string} props.topText - Text at the top of the card
   * @param {string} props.bottomText - Text at bottom of card - usually timestamp
   * @param {boolean} props.includeTooltip - Should MUI tooltip should be included in the card
   * @param {string} props.toolTipText - The text that should show in the MUI tooltip
   */
  const {
    count, revenue, topText, bottomText, includeTooltip, toolTipText,
  } = props;

  const param = useParams();
  const navigate = useNavigate();

  const handleCodesClick = () => {
    /**
     * Navigate to the codes page when the ungrouped codes card is clicked
     */
    navigate(`/client/${param.client}/codes`, {
      replace: false,
      state: { client: param.client },
    });
  };

  return (
    <Tooltip title={includeTooltip ? '' : toolTipText} placement="bottom" key={includeTooltip ? '' : toolTipText}>
      <Card className={topText === 'Ungrouped codes' ? 'codes-card codes-widget' : 'codes-card'} variant="outlined" sx={{ p: 3 }} onClick={topText === 'Ungrouped codes' ? () => handleCodesClick() : null}>
        <p className="codes-subtext">{topText}</p>
        <p className="class-count">{count}</p>
        <p className="class-count">{revenue}</p>
        <p className="last-updated-timestamp">
          {bottomText}
        </p>
      </Card>
    </Tooltip>
  );
}

export default CodesCard;

CodesCard.propTypes = {
  count: PropTypes.number,
  revenue: PropTypes.string,
  topText: PropTypes.string.isRequired,
  bottomText: PropTypes.string,
  includeTooltip: PropTypes.bool,
  toolTipText: PropTypes.string,
};
