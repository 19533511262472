/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useContext, useEffect, useState,
} from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import {
  DataGridPremium, useGridApiRef, GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridActionsCellItem,
  gridClasses,
} from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

import PropTypes from 'prop-types';
import APIWrapper from '../utils/graphqlwrapper';
import { updateTablePreferenceLocalStorage, getLocalStorageTablePref } from '../utils';
import theme from './themes/Theme';
import AppContext from '../context';
import logger from '../utils/logger';

function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{
        justifyContent: 'flex-start',
      }}
    >
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        excelOptions={{
          columnsStyles: {
            // format percentage
            '% of total': { numFmt: '0.00%' },
          },
        }}
      />
      <Box sx={{ flex: 1 }} />
    </GridToolbarContainer>
  );
}

function ServerTable(props) {
  const {
    columns,
    pageState,
    updatePageState,
    filters = {},
    queryName,
    checkboxSelection,
    columnVisibilityModel,
    setColumnVisibilityModel,
    setSelectedRows,
    createRows,
    tablePrefix,
    pinnedColumns,
    setOrderedColumns,
    handleEditClick, // 052523 part of a fix
    handleDeleteClick, // 052523 part of a fix
    getDefaultColumns,
    sortModel = {},
    customRowHeight = null,
    isRowSelectable,
  } = props;

  const rowsPerPage = 50;
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: rowsPerPage,
  });

  const apiRef = useGridApiRef();
  const { setShouldLogOut } = useContext(AppContext);

  const [gqlNextToken, setGqlNextToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [pageInfo, setPageInfo] = useState({
    totalRowCount: 99,
    realTotal: 0,
  });

  const [maxPage, setMaxPage] = useState(-1);

  // if we go back a page we don't want to requery;
  const [existingPages, setExistingPages] = useState([]);

  // updates page - if it's a page we've been to it redisplays that data
  // if it's a new page get next page of results from api
  const updatePage = async (pm) => {
    setIsLoading(true);
    if (pm.page > maxPage) {
      // new page, only run if we have filters
      if (Object.keys(filters).length > 0) {
        try {
          const results = await APIWrapper.queryApi(
            { query: queryName },
            setShouldLogOut,
            // false,
            {
              filter: filters,
              sort: sortModel || {},
              limit: rowsPerPage,
              nextToken: gqlNextToken || null,
            },
          ).catch((err) => { logger.error(err); });
          setGqlNextToken(results.nextToken);

          const newrows = createRows(results.data);

          updatePageState((old) => ({ ...old, data: newrows, total: results.total }));

          const newpages = existingPages.concat([newrows]);
          setIsLoading(false);
          // eslint-disable-next-line max-len
          setPageInfo((old) => ({ ...old, realTotal: results.total, totalRowCount: results.total }));
          setExistingPages(newpages);
          setMaxPage(pm.page);
        } catch (err) {
          logger.error(err);
        }
      }
    } else {
      // we've already been to this page before - redisplay data
      setIsLoading(false);
      updatePageState((old) => ({ ...old, data: existingPages[pm.page] }));
    }
  };

  // used to show how many total rows from api
  const [rowCountState, setRowCountState] = useState(
    pageInfo.totalRowCount || 0,
  );

  // keeps track of if we should do a fresh search
  const [shouldRefreshQuery, setShouldRefreshQuery] = useState(false);

  // shared logic with Table for column (heading) order table pref
  const onColumnOrderChange = (params) => {
    const { oldIndex, targetIndex } = params;
    let curColumns = getLocalStorageTablePref(tablePrefix, 'column');
    if (curColumns === null || !curColumns) {
      curColumns = getDefaultColumns();
      updateTablePreferenceLocalStorage(tablePrefix, 'column', columns, setOrderedColumns);
    }
    const updatedColumns = curColumns.slice(0);
    const removedCol = updatedColumns.splice(oldIndex, 1);
    updatedColumns.splice(targetIndex, 0, removedCol[0]);

    if (tablePrefix === 'codes' || tablePrefix === 'sources' || tablePrefix === 'codes-adv' || tablePrefix === 'sources-adv') {
      let elToUpdate = updatedColumns[0];
      // getActions depends on which table (Sources has delete but Codes does not)
      if (tablePrefix === 'codes') {
        elToUpdate = {
          ...elToUpdate,
          getActions: (row) => [
            <GridActionsCellItem
              icon={<EditIcon color={row.row.from_goop ? 'disabled' : ''} />}
              label="Edit"
              className="textPrimary"
              onClick={(ev) => handleEditClick(ev, row)}
              color="inherit"
              size="medium"
            />],
        };
      } else {
        elToUpdate = {
          ...elToUpdate,
          getActions: (row) => [
            <GridActionsCellItem
              icon={<EditIcon color={row.row.from_goop ? 'disabled' : ''} />}
              label="Edit"
              className="textPrimary"
              onClick={(ev) => handleEditClick(ev, row)}
              color="inherit"
              size="medium"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon color={row.row.from_goop ? 'disabled' : ''} />}
              label="Delete"
              onClick={(ev) => handleDeleteClick(ev, row)}
              color="inherit"
              size="medium"
            />,
          ],
        };
      }
      updatedColumns[0] = elToUpdate;
    }

    updateTablePreferenceLocalStorage(tablePrefix, 'column', updatedColumns);
    setOrderedColumns(updatedColumns);
  };

  useEffect(() => {
    // new query! - reset everything
    setExistingPages([]);
    updatePageState((old) => ({
      ...old, data: [], total: 0,
    }));
    setMaxPage(-1);
    setGqlNextToken(null);
    apiRef.current.setPage(0);
    setShouldRefreshQuery(true);
  }, [filters, queryName]);

  useEffect(() => {
    if (shouldRefreshQuery) {
      updatePage(paginationModel);
      setShouldRefreshQuery(false);
    }
  }, [shouldRefreshQuery]);

  useEffect(() => {
    // on page change, if we've been to this page already use 'saved' data, otw query & get new rows
    updatePage(paginationModel);
  }, [paginationModel]);

  useEffect(() => {
    setRowCountState((prevRowCountState) => (pageInfo.totalRowCount !== undefined
      ? pageInfo.totalRowCount
      : prevRowCountState));
  }, [pageInfo.totalRowCount, setRowCountState]);

  return (
    <div style={{ height: 400, width: '100%' }}>
      <div
        style={{ flexGrow: 1 }}
      >
        {checkboxSelection && ( // transactions and codes tables
          <>
            {pageState.isPreview
            && <h3 style={{ color: 'primary.main' }}>Preview</h3>}

            <DataGridPremium
              sx={{
                border: pageState.isPreview ? 2 : 0,
                borderColor: pageState.isPreview ? 'primary.light' : 'white',
                borderWidth: pageState.isPreview ? 4 : 0,
                [`& .${gridClasses.row}.odd`]: {
                  backgroundColor: theme.palette.datagrid.differentiation,
                },
                [`& .${gridClasses.row}.odd, & .${gridClasses.row}.even`]: {
                  '&:hover, &.Mui-hovered, &.Mui-selected': {
                    backgroundColor: theme.palette.datagrid.selectedOrHover,
                  },
                },
              }}
              apiRef={apiRef}
              autoHeight
              rows={pageState.isPreview ? pageState.previewData : pageState.data}
              columns={columns}
              disableRowSelectionOnClick
              slots={{ toolbar: CustomToolbar, loadingOverlay: LinearProgress }}
              rowCount={pageInfo.totalRowCount}
              loading={isLoading}
              pagination
              pageSizeOptions={[rowsPerPage]}
              pageSize={rowsPerPage}
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={setPaginationModel}
              checkboxSelection
              onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const userSelection = pageState.data.filter(
                  (row) => selectedIDs.has(row.id),
                );
                setSelectedRows(userSelection);
              }}
              isRowSelectable={isRowSelectable}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
              getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
              localeText={{
                MuiTablePagination: {
                  labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} of ${
                    pageInfo.realTotal >= 10000 ? '10000 +' : count
                  }`,
                },
              }}
              getRowHeight={() => customRowHeight}
            />
          </>
        )}
        {!checkboxSelection && (
          <DataGridPremium
            sx={{
              [`& .${gridClasses.row}.odd`]: {
                backgroundColor: '#F0F0F0',
              },
              [`& .${gridClasses.row}.odd, & .${gridClasses.row}.even`]: {
                '&:hover, &.Mui-hovered, &.Mui-selected': {
                  backgroundColor: theme.palette.datagrid.selectedOrHover,
                },
              },
            }}
            apiRef={apiRef}
            autoHeight
            rows={pageState.data || []}
            columns={columns}
            slots={{ toolbar: CustomToolbar, loadingOverlay: LinearProgress }}
            rowCount={pageInfo.totalRowCount}
            initialState={{ ...pinnedColumns }}
            loading={isLoading}
            pagination
            pageSizeOptions={[rowsPerPage]}
            pageSize={rowsPerPage}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newColVisibilityModel) => updateTablePreferenceLocalStorage(tablePrefix, 'column-visibility', newColVisibilityModel, setColumnVisibilityModel)}
            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
            onColumnOrderChange={onColumnOrderChange}
            localeText={{
              MuiTablePagination: {
                labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} of ${
                  pageInfo.realTotal >= 10000 ? '10000 +' : count
                }`,
              },
            }}
            getRowHeight={() => customRowHeight}
          />
        )}
      </div>
    </div>
  );
}

export default ServerTable;

ServerTable.propTypes = {
  pageState: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updatePageState: PropTypes.func.isRequired,
  columns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  columnVisibilityModel: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setColumnVisibilityModel: PropTypes.func,
  filters: PropTypes.oneOfType([PropTypes.object]),
  queryName: PropTypes.string.isRequired,
  checkboxSelection: PropTypes.bool,
  setSelectedRows: PropTypes.func,
  createRows: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  tablePrefix: PropTypes.string,
  pinnedColumns: PropTypes.oneOfType([PropTypes.object]),
  setOrderedColumns: PropTypes.func,
  getDefaultColumns: PropTypes.func,
  sortModel: PropTypes.oneOfType([PropTypes.object]),
  customRowHeight: PropTypes.string,
  isRowSelectable: PropTypes.func,
};
