import { Auth, API } from 'aws-amplify';
import * as query from '../graphql/queries';
import * as mutation from '../graphql/mutations';
import * as subscription from '../graphql/subscriptions';
import logger from './logger';

/// if error, try to wait for fed login and try again ??
const APIWrapper = {
  /**
   * The graphQL operations that our API allows
   */
  operations: {
    query,
    mutation,
    subscription,
  },
  /**
   * queryApi - Call the GraphQL API from the client and run a query
   * @param {Object} operation
   * @param {'query' | 'mutation' | 'subscription'} opration.opType The graphql operation to perform
   * @param {string} operation.opName The name of the operation to perform
   * @param {boolean} setShouldLogOut flag if user is authorized to perform this operation or not
   * @param {Object} [queryVars] Parameters for the query variables
   * @returns
   */
  queryApi(operation, setShouldLogOut, queryVars) {
    async function callGraphQL(operationToCall, apiQueryVars) {
      const [opType, opName] = Object.entries(operationToCall)[0];
      // Check that the operation type is allowed
      if (!Object.keys(APIWrapper.operations).includes(opType)) {
        throw new Error(
          `${opType} is not a valid value for this API operation. Please use either 'query', 'mutation', or 'subscription`,
        );
      }
      let data;
      let errors;
      let items;
      let nextToken;
      let aggregateItems;
      let total;
      let filteredRes;
      const results = [];
      const errorMessages = [];
      try {
        const currUser = await Auth.currentAuthenticatedUser();
        ({ data, errors } = await API.graphql({
          authMode: 'OPENID_CONNECT',
          authToken: currUser.token,
          query: APIWrapper.operations[opType][opName],
          variables: {
            ...apiQueryVars,
            ...(nextToken && { nextToken }),
          },
        }));
      } catch (e) {
        ({ data, errors } = e);
        const actualerrors = errors;
        // TODO: Figure out how we want to handle these
        // non-breaking errors
        if (actualerrors && Object.keys(actualerrors).length) {
          Object.keys(actualerrors).forEach((err) => {
            const { message } = actualerrors[err];
            errorMessages.push(message);
            logger.debug(message);
          });
        }
      }
      if (data) {
        if (errorMessages.includes('Request failed with status code 401')) {
          setShouldLogOut(true);
        } else if (data[opName] !== null && data[opName].items) {
          ({
            [opName]: {
              items, nextToken, total, aggregateItems,
            },
          } = data);
          results.push(items);

          filteredRes = results[0].filter((item) => item !== null);
        } else {
          // for operations where results are a single record
          results.push(data[opName]);
          filteredRes = results.filter((item) => item !== null);
        }
      } else {
        filteredRes = [];
        logger.error(
          "No data returned from API - this could be because a search operation could not be completed b/c open search didn't have any data backfilled",
        );
      }

      return {
        data: [filteredRes],
        errors,
        nextToken,
        total,
        aggregateItems,
      };
    }
    return callGraphQL(operation, queryVars);
  },

  /**
   * An additional helper function to format filters used in GraphQL API queries.
   * @param {[Object]} a list of objs that follow the format: {field: {operation: value}}
   * Returns a the filters in the following nested format that can be used in an api call:
   * {
   *  field1: {
   *    operation: value,
   *    and: {
   *      field2: {
   *        operation: value,
   *        and: {
   *          ...
   *        }
   *      }
   *    }
   *  }
   * }
   * or false if something goes wrong
   */
  formatFilters(filterList) {
    let formattedFilters = {};
    let error = false;
    filterList.forEach((filter) => {
      // should only have one key (field)
      if (Object.keys(filter).length === 1) {
        try {
          const field = Object.keys(filter)[0];
          const operationObj = filter[field];
          const op = Object.keys(operationObj)[0];
          const value = filter[field][op];

          let newFilters = {};
          if (Object.keys(formattedFilters).length > 0) {
            newFilters = {
              and: formattedFilters,
            };
          }
          newFilters[field] = {};
          newFilters[field][op] = value;

          formattedFilters = newFilters;
        } catch (err) {
          logger.error(err);
          logger.debug("Couldn't format filters");
          error = true;
        }
      } else {
        error = true;
      }
    });
    if (error) {
      return false;
    }
    return formattedFilters;
  },
};

export default APIWrapper;
