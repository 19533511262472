import logger from './logger';

// converting string in localStorage to usable list - returns list
function localStorageToList(favs) {
  const favArray = favs.split(',');
  return favArray;
}

// fetch a list from local storage given the key - returns list
function getListFromLocalStorage(lsKey) {
  let items = localStorage.getItem(lsKey);
  if (items) {
    items = localStorageToList(items);
  } else {
    items = [];
  }
  return items;
}

function getFavoriteClients() {
  return getListFromLocalStorage('favoritedClients');
}

function getRecentClients() {
  return getListFromLocalStorage('recentClients');
}

// Takes the desired localStorage key and the new value (a list) for that key. Updates local storage
function setListInLocalStorage(lsKey, newlist) {
  if (Array.isArray(newlist)) {
    localStorage.setItem(lsKey, newlist);
  } else if (typeof (newlist) === 'string') {
    try {
      localStorage.setItem(lsKey, `[${newlist}]`);
    } catch (e) {
      logger.error(`couldn't parse ${lsKey} to set favorites - leaving localStorage as is`);
    }
  }
}

function setFavoriteClients(favoriteList) {
  setListInLocalStorage('favoritedClients', favoriteList);
}

function setRecentClients(recentList) {
  setListInLocalStorage('recentClients', recentList);
}

// add a singular item to whatever list is in localStorage for a given key
function addItemToListInLocalStorage(lsKey, newitem) {
  const currList = getListFromLocalStorage(lsKey);
  if (!currList.includes(newitem)) {
    currList.push(newitem);
    setListInLocalStorage(lsKey, currList);
  } else if (lsKey === 'recentClients') {
    // if we've revisited a page we went to recently, put it at the end of the list
    currList.push(currList.splice(currList.indexOf(newitem), 1).pop());
    setListInLocalStorage(lsKey, currList);
  }
}

function addFavoriteClient(newFav) {
  addItemToListInLocalStorage('favoritedClients', newFav);
}

function keepFirstN(lsKey, maxSize) {
  let currList = getListFromLocalStorage(lsKey);
  if (currList.length >= maxSize) {
    const oldest = currList.length - maxSize;
    currList = currList.slice(oldest, currList.length);
    setListInLocalStorage(lsKey, currList);
  }
}

function addRecentClient(newRecent) {
  addItemToListInLocalStorage('recentClients', newRecent);
  keepFirstN('recentClients', 5);
}

function removeItemFromListInLocalStorage(lsKey, removeItem) {
  let currItems = getListFromLocalStorage(lsKey);
  if (currItems.includes(removeItem)) {
    currItems = currItems.filter((item) => item !== removeItem);
    setListInLocalStorage(lsKey, currItems);
  }
}

function removeFavoriteClient(client) {
  removeItemFromListInLocalStorage('favoritedClients', client);
}

function removeRecentClient(client) {
  removeItemFromListInLocalStorage('recentClients', client);
}

function checkIfFavorite(client) {
  const currentFavs = getFavoriteClients();
  return currentFavs.includes(client);
}

export {
  getFavoriteClients,
  getRecentClients,
  setFavoriteClients,
  setRecentClients,
  addFavoriteClient,
  addRecentClient,
  removeFavoriteClient,
  removeRecentClient,
  checkIfFavorite,
};
