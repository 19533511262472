import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';

import ClientTextField from '../clientFields/ClientTextField';
import ClientDropDown from '../clientFields/ClientDropDown';

function GeneralTab({
  setFunction, data, permissions, structure, isNew, hasTDCAdminPermission,
}) {
  const setter = setFunction;
  const [liveData, setLiveData] = useState(data);
  const [userPermissions, setUserPermissions] = useState(permissions);
  const [dataStructure, setDataStructure] = useState(structure);

  useEffect(() => {
    setLiveData(data);
    setUserPermissions(permissions);
    setDataStructure(structure);
  }, [data]);

  return (
    <div id="general-info" className="client-tab">
      <ClientTextField
        setFunction={setter}
        data={liveData.client_full_name}
        attribute="client_full_name"
        label="Client Full Name"
        required
        permissions={userPermissions}
        structure={dataStructure}
        isDisabled={!hasTDCAdminPermission}
      />
      {isNew
      && (
      <ClientTextField
        setFunction={setter}
        data={liveData.client_id}
        attribute="client_id"
        label="Client Code"
        required
        helperText="The client code cannot be updated once a client is created"
        permissions={userPermissions}
        structure={dataStructure}
      />
      )}
      <ClientTextField
        setFunction={setter}
        data={liveData.jira_project_name}
        attribute="jira_project_name"
        label="Jira Project Name"
        required
        permissions={userPermissions}
        structure={dataStructure}
        isDisabled={!hasTDCAdminPermission}
      />
      <ClientDropDown
        setFunction={setter}
        data={liveData.client_type}
        attribute="client_type"
        label="Client Type"
        required
        permissions={userPermissions}
        structure={dataStructure}
        isDisabled={!hasTDCAdminPermission}
      />
    </div>
  );
}

export default GeneralTab;

GeneralTab.propTypes = {
  permissions: PropTypes.oneOfType([PropTypes.object]),
  structure: PropTypes.oneOfType([PropTypes.object]),
  setFunction: PropTypes.func,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isNew: PropTypes.bool,
  hasTDCAdminPermission: PropTypes.bool,
};
