import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import FallbackOnError from '../components/FallbackOnError';
import logger from '../utils/logger';
import { logReactErrBoundaryError } from '../utils';

export function ProfileComponent() {
  useEffect(() => {
    document.title = 'MissionPortal | Profile';
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={FallbackOnError}
      onError={logReactErrBoundaryError}
      onReset={(details) => {
        logger.info('details', details);
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      <div>
        <a href="/logout">Log Out</a>
      </div>
    </ErrorBoundary>
  );
}

export default ProfileComponent;
