export const REACT_APP_ENVIRON = process.env.REACT_APP_ENVIRON
  ? process.env.REACT_APP_ENVIRON
  : 'staging';

let auth0Domain;
if (REACT_APP_ENVIRON === 'production') {
  auth0Domain = 'missionportal.us.auth0.com';
} else if (REACT_APP_ENVIRON === 'staging') {
  auth0Domain = 'missionportal-staging.us.auth0.com';
} else {
  auth0Domain = 'missionportal-dev.us.auth0.com';
}

export const authconfig = {
  domain: auth0Domain,
  clientId:
    REACT_APP_ENVIRON === 'production'
      ? 'kVsC4ZyVitGajGbFlrpMH23y3nU0l3kx'
      : '0FgdzO12GTmcudDpnop1tfBMqRTRhIPQ',
  audience: `https://${auth0Domain}/api/v2/`,
};

export const awsconfig = {
  aws_project_region: 'us-east-1',
  aws_appsync_graphqlEndpoint:
    REACT_APP_ENVIRON === 'production'
      ? 'https://api.missionportal.com/graphql'
      : 'https://og5xxsr3ebaohiht67tipaibzm.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'OPENID_CONNECT',
  aws_cognito_identity_pool_id:
    REACT_APP_ENVIRON === 'production'
      ? 'us-east-1:da1de39a-9658-4835-bddc-c1a64eae1c3c'
      : 'us-east-1:6d8a74ba-eda3-4e0b-befa-032766e28249',
  aws_cognito_region: 'us-east-1',
};

const dromoFrontendLicense = {
  production: '9cce1b92-9df8-4b6d-904b-40f231513101',
  staging: '171ccc59-1c10-434d-9a39-e4ea3bba0445',
};
export const dromoFrontendLic = REACT_APP_ENVIRON === 'production'
  ? dromoFrontendLicense.production
  : dromoFrontendLicense.staging;
// expires on Jun 8, 2025
export const MuiPremiumLic = '6e52e51df7e8b853e2a23bf7c5d6d26bTz05MjA1NCxFPTE3NDk0MDQwNzAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y';
