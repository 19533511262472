import React from 'react';
import PropTypes from 'prop-types';

function FallbackOnError({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <button type="button" onClick={resetErrorBoundary}>Retry</button>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  );
}

FallbackOnError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object]),
  resetErrorBoundary: PropTypes.func,
};

export default FallbackOnError;
