import React from 'react';
import PropTypes from 'prop-types';
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridActionsCellItem,
  gridClasses,
} from '@mui/x-data-grid-premium';

import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { updateTablePreferenceLocalStorage, getLocalStorageTablePref } from '../utils';
import theme from './themes/Theme';
import { getFavoriteClients } from '../utils/favsAndRecentClients';

/* props for Table component should contain:
- columns (required): The columns of the table in an array formatted like this ->
    [{field: <fieldName>, headerName: <columnHeader>, editable: <boolean>}]
    (see Codes.js or ClientList.js for examples)
- setOrderedColumns: Setter to track column order (for mui datagrid)
- customSetOrdered: Setter to track column order (for mui datagrid)
    (used by ClientList and ImportHistory) or any table that does not have an actions column
- columnVisibilityModel: The columns of the table that should be hidden by default (object).
    Ex {adaimemberdate: false,} should be added to the DataGridPremium.
    If all columns should be visible by default this should be `{}` initially.
- setColumnVisibilityModel: updates columnVisibilityModel state in the component that uses Table.
    ex: see columnVisibility state definition in Codes/Sources components.
- rowClickFunc (optional): include if you want navigation/redirect, etc on row click
    (not for use on rows that are editable)
- sortModel (optional): array of objects which specifies which column(s) to sort by and how
- pageState (required): Object containing info on the pagination information
    ex: { isLoading: boolean value for whether the table should show that it's loading data
          page: the current page being viewed
          pageSize: amount of rows showing per page, defaults to 50,
          data: [Row data that will be displayed],
          total: Total row count that we are working with, basically length of data array}
- updatePageState (required): setter function for updating page state in parent
- tablePrefix (required): (used to get/set table preferences from localStorage) string
which can be 'client' | 'codes' | 'sources'; references table name
- filterModel (required): (used to get/set table preferences from localStorage and in
component state) object used by mui to store user-defined filters for data-grid/table
- setFilterModel (required): (used to get/set table preferences from localStorage and in
component state) setter function (defined with useState) to track a table's filterModel
- pinnedColumns (optional) https://mui.com/x/react-data-grid/column-pinning/
- setSortModel: setter for react state for controlled mui sort models (used by Sources, Codes,
  ClientList)
- handleEditClick (optional): only used for Sources/Codes tables to handle a potential
issue with the column ordering table preference (getActions not defined in columns list first item)
- handleDeleteClick (optional): only used for Sources table to handle a potential issue
with the column ordering table preference (getActions not defined in columns list first item)
- setSelectedRows (optional): only used for Codes, allows you to set the selected rows in state
- isRowSelectable (optional): only used for Codes, determines if a row is selectable or not
- customRowHeight (optional): if "auto" makes row height dynamic, else default to null
*/

function Table(props) {
  const {
    sortModel = [],
    columns,
    rowClickFunc,
    columnVisibilityModel,
    setColumnVisibilityModel,
    pageState,
    updatePageState,
    filterModel,
    setFilterModel,
    tablePrefix,
    pinnedColumns,
    setSortModel,
    setOrderedColumns,
    handleEditClick,
    handleDeleteClick,
    setSelectedRows,
    isRowSelectable,
    initialState,
    sumBySource,
    getDefaultColumns,
    customSetOrdered,
    customRowHeight = null,
  } = props;

  const editRowInitialState = sumBySource ? initialState : '';
  const otherInitialState = sumBySource ? initialState : { ...pinnedColumns };

  const favClients = getFavoriteClients();

  const onColumnOrderChange = (params) => {
    const { oldIndex, targetIndex } = params;
    let curColumns = getLocalStorageTablePref(tablePrefix, 'column');
    if (curColumns === null) {
      curColumns = getDefaultColumns();
      updateTablePreferenceLocalStorage(tablePrefix, 'column', columns, setOrderedColumns);
    }
    const updatedColumns = curColumns.slice(0);
    const removedCol = updatedColumns.splice(oldIndex, 1);
    updatedColumns.splice(targetIndex, 0, removedCol[0]);

    if (tablePrefix === 'codes' || tablePrefix === 'sources') {
      let elToUpdate = updatedColumns[0];
      // getActions depends on which table (Sources has delete but Codes does not)
      if (tablePrefix === 'codes') {
        elToUpdate = {
          ...elToUpdate,
          getActions: ({ id }) => [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={(ev) => handleEditClick(ev, id)}
              color="inherit"
              size="medium"
            />],
        };
      } else {
        elToUpdate = {
          ...elToUpdate,
          getActions: ({ id }) => [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={(ev) => handleEditClick(ev, id)}
              color="inherit"
              size="medium"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={(ev) => handleDeleteClick(ev, id)}
              color="inherit"
              size="medium"
            />,
          ],
        };
      }
      updateTablePreferenceLocalStorage(tablePrefix, 'column', updatedColumns);
      updatedColumns[0] = elToUpdate;
      setOrderedColumns(updatedColumns);
    } else if (tablePrefix === 'clientlist' || tablePrefix === 'importhistory') {
      customSetOrdered(updatedColumns);
    }
  };

  // TODO: figure out how to move CustomToolbar out of Table,
  // I tried doing this multiple ways but kept getting this error:
  /**
   * MUI: GridErrorHandler - An unexpected error occurred.
   * Error: Element type is invalid: expected a string
   * (for built-in components) or a class/function (for composite components)
   * but got: object.
   */
  // eslint-disable-next-line react/no-unstable-nested-components
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: 'flex-start',
        }}
      >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          excelOptions={{
            columnsStyles: {
              // format percentage
              '% of total': { numFmt: '0.00%' },
            },
          }}
        />
        <Box sx={{ flex: 1 }} />
      </GridToolbarContainer>
    );
  }

  // table with multiselect checkboxes
  if (tablePrefix === 'codes') {
    return (

      <DataGridPremium
        sx={{
          height: 'auto',
          width: '100%',
          bgcolor: '#FFFFFF',
          '& .MuiDataGrid-cell--editable': {
            bgcolor: '#FFFFFF',
          },
          [`& .${gridClasses.row}.odd`]: {
            backgroundColor: theme.palette.datagrid.differentiation,
          },
          [`& .${gridClasses.row}.odd, & .${gridClasses.row}.even`]: {
            '&:hover, &.Mui-hovered, &.Mui-selected': {
              backgroundColor: theme.palette.datagrid.selectedOrHover,
            },
          },
        }}
        initialState={{ editRowInitialState }}
        sortModel={sortModel}
        autoHeight
        columns={columns}
        disableRowSelectionOnClick
        slots={{ toolbar: CustomToolbar, loadingOverlay: LinearProgress }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
        pagination
        rows={pageState.isPreview ? pageState.previewData : pageState.data}
        loading={pageState.isLoading}
        pageSize={pageState.pageSize}
        pageSizeOptions={[50, 100, 200]}
        onPageChange={(newPage) => updatePageState((old) => ({ ...old, page: newPage }))}
        onPageSizeChange={
              (newPageSize) => updatePageState((old) => ({ ...old, pageSize: newPageSize }))
            }
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => updateTablePreferenceLocalStorage(tablePrefix, 'filter', newFilterModel, setFilterModel)}
        onColumnOrderChange={onColumnOrderChange}
        onSortModelChange={(newSortModel) => updateTablePreferenceLocalStorage(tablePrefix, 'sort', newSortModel, setSortModel)}
        checkboxSelection
        onRowSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const userSelection = pageState.data.filter(
            (row) => selectedIDs.has(row.id),
          );
          setSelectedRows(userSelection);
        }}
        isRowSelectable={isRowSelectable}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
      />
    );
  }

  // TODO think this gets used by client list, sources
  // Table without multiselect checkboxes
  return (
    <div>
      <DataGridPremium
        sx={{
          '& .highlight-fav': {
            backgroundColor: theme.palette.datagrid.selectedOrHover,
            '&:hover, &.Mui-hovered, &.Mui-selected': {
              backgroundColor: theme.palette.datagrid.darkerSelect,
            },
          },
          [`& .${gridClasses.row}.odd`]: {
            backgroundColor: theme.palette.datagrid.differentiation,
          },
          [`& .${gridClasses.row}.odd, & .${gridClasses.row}.even`]: {
            '&:hover, &.Mui-hovered, &.Mui-selected': {
              backgroundColor: theme.palette.datagrid.selectedOrHover,
            },
          },
        }}
        initialState={otherInitialState}
        sortModel={sortModel}
        autoHeight
        columns={columns}
        pinnedRows={{ top: pageState.favoriteClients }}
        disableRowSelectionOnClick
        onRowClick={rowClickFunc}
        slots={{ toolbar: CustomToolbar, loadingOverlay: LinearProgress }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newColVisibilityModel) => updateTablePreferenceLocalStorage(tablePrefix, 'column-visibility', newColVisibilityModel, setColumnVisibilityModel)}
        pagination
        rows={pageState.data}
        loading={pageState.isLoading}
        pageSize={pageState.pageSize}
        pageSizeOptions={[50, 100, 200]}
        onPageChange={(newPage) => updatePageState((old) => ({ ...old, page: newPage }))}
        onPageSizeChange={
            (newPageSize) => updatePageState((old) => ({ ...old, pageSize: newPageSize }))
          }
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => updateTablePreferenceLocalStorage(tablePrefix, 'filter', newFilterModel, setFilterModel)}
        onColumnOrderChange={onColumnOrderChange}
        onSortModelChange={(newSortModel) => updateTablePreferenceLocalStorage(tablePrefix, 'sort', newSortModel, setSortModel)}
        getRowClassName={(params) => {
          if (tablePrefix === 'clientlist' && favClients && Array.isArray(favClients) && favClients.includes(params.row.code)) {
            return 'highlight-fav';
          }
          return (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd');
        }}
        getRowHeight={() => customRowHeight}
      />
    </div>
  );
}

export default Table;

Table.propTypes = {
  rowClickFunc: PropTypes.func,
  pageState: PropTypes.oneOfType([PropTypes.object]),
  updatePageState: PropTypes.func,
  columns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  sortModel: PropTypes.oneOfType([PropTypes.array]),
  columnVisibilityModel: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setColumnVisibilityModel: PropTypes.func,
  filterModel: PropTypes.oneOfType([PropTypes.object]),
  setFilterModel: PropTypes.func,
  tablePrefix: PropTypes.string,
  pinnedColumns: PropTypes.oneOfType([PropTypes.object]),
  setSortModel: PropTypes.func,
  setOrderedColumns: PropTypes.func,
  handleEditClick: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  initialState: PropTypes.oneOfType([PropTypes.object]),
  sumBySource: PropTypes.bool,
  setSelectedRows: PropTypes.func,
  isRowSelectable: PropTypes.func,
  getDefaultColumns: PropTypes.func,
  customSetOrdered: PropTypes.func,
  customRowHeight: PropTypes.string,
};
