import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
      <Box sx={{ p: 3 }}>
        <Typography component="span">{children}</Typography>
      </Box>
      )}
    </div>
  );
}

export default TabPanel;

TabPanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};
