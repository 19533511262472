/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import ReportsCard from '../../components/ReportsCard';
import deliverabilityDash from '../../assets/reporting_views/ssr_deliverability_dash.png';

function SSREmailLanding() {
  useEffect(() => {
    document.title = 'MissionPortal | SSR Email';
  }, []);

  const { client } = useParams();

  return (
    <div className="App">
      <header className="App-header">
        <h2>Strategic Services - Email</h2>
      </header>
      <h5>
        Email reporting for all clients, not limited to Co-Op Members.
      </h5>
      <Box sx={{ flexGrow: 1 }}>
        <div className="reporting-row-container">
          <ReportsCard
            image={deliverabilityDash}
            imageAlt="Deliverability report"
            title="Deliverability Dashboard"
            description="Measures whether emails are landing in inboxes, broken out by domain"
            bullets={[]}
            route={`/client/${client}/Strategy/Email/deliverability_dashboard`}
            buttonText="View Report"
          />
        </div>
      </Box>
    </div>
  );
}

export default SSREmailLanding;
