import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import DromoUploader from 'dromo-uploader-react';
import {
  Box, InputLabel, MenuItem, FormControl, FormControlLabel,
  FormLabel, Select, RadioGroup, Radio, Tooltip,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PropTypes from 'prop-types';

import { dromoFrontendLic } from '../aws-config';
import logger from '../utils/logger';
import ImportHistory from '../components/client/ImportHistory';

function TransactionsExclusions(props) {
  const { client } = props;
  // for dromo upload
  const [transactionsCrm, setTransactionsCrm] = useState('actblue'); // alt 'ngp_everyaction'
  const [excludeFlag, setExcludeFlag] = useState(true);
  const lKey = dromoFrontendLic;

  const handleCrmChange = (event) => {
    setTransactionsCrm(event.target.value);
  };

  const handleExcludeFlagChange = (event) => {
    setExcludeFlag(event.target.value === 'true');
  };

  return (
    <div>
      <Box
        sx={{ mb: 1 }}
      >
        <div className="import-options">
          <div className="exclude-flag-group">
            <FormControl
              sx={{ mx: 1 }}
              focused={false}
            >
              <FormLabel id="exclude-flag-label">
                Exclude Flag
                <Tooltip title="Select whether to exclude or unexclude transactions in this file">
                  <HelpOutlineIcon fontSize="small" />
                </Tooltip>
              </FormLabel>
              <RadioGroup defaultValue="true" aria-label="exclude-flag" name="exclude-flag" onChange={handleExcludeFlagChange}>
                <FormControlLabel
                  value="true"
                  name="exclude-flag-true"
                  control={<Radio />}
                  label="True"
                />
                <FormControlLabel
                  value="false"
                  name="exclude-flag-false"
                  control={<Radio />}
                  label="False"
                />
              </RadioGroup>
            </FormControl>
          </div>

          {client && client === 'emily' && (
          <FormControl
            sx={{ mx: 1 }}
          >
            <InputLabel id="transaction-crm-select">Fundraising CRM</InputLabel>
            <Select
              labelId="transactions-crm-label"
              id="transaction-crm-select"
              value={transactionsCrm}
              label="Fundraising CRM"
              onChange={handleCrmChange}
            >
              <MenuItem value="actblue">Actblue</MenuItem>
              <MenuItem value="ngp_everyaction">NGP</MenuItem>
            </Select>
          </FormControl>
          )}
        </div>

        <DromoUploader
          className="dromo-uploader"
          licenseKey={lKey}
          fields={[
            {
              label: 'Recipient ID',
              key: 'recipientId',
              requireMapping: true,
            },
            {
              label: 'Lineitem ID',
              key: 'lineItemId',
              requireMapping: true,
            },
            {
              label: 'Exclude Flag',
              key: 'exclude_flag',
              requireMapping: true,
            },
          ]}
          settings={{
            styleOverrides: {
              global: {
                textColor: '#292d2d',
                backgroundColor: '#faf3eb',
              },
              primaryButton: {
                borderRadius: '4px',
                backgroundColor: '#00a69c',
                textColor: '#ffffff',
                border: '1px solid #00a69c',
                hoverBackgroundColor: '#03746d',
                hoverTextColor: '#ffffff',
                hoverBorder: '1px solid #00746d',
              },
              stepperBar: {
                currentColor: '#00a69c',
                completeColor: '#5e6262',
                incompleteColor: '#292d2d',
              },
            },
            importIdentifier: `Transaction Bulk ${excludeFlag === true ? 'Exclusions' : 'Unexclusions'}`,
            backendSyncMode: 'FULL_DATA',
            allowCustomFields: true,
          }}
          user={{
            id: client,
            companyName: transactionsCrm,
            companyId: transactionsCrm === 'ngp_everyaction' ? '87069' : '',
          }}
        // Do something with the data here
          onResults={(response, metadata) => {
            logger.info('response', response);
            logger.info('metadata', metadata);
          }}
          style={{
            height: '40px',
            width: '250px',
            borderRadius: '4px',
            color: '#ffffff',
            padding: '6px 16px',
            fontSize: '14px',
          }}
          bulkRowHooks={[
            // handle exclude flag and pass value according to user selection
            (records) => records.map((record) => {
              const newRecord = record;

              newRecord.row.exclude_flag = { value: excludeFlag };
              newRecord.row.exclude_flag.info = [
                {
                  message: `Exclude Flag set to ${excludeFlag}`,
                  level: 'info',
                },
              ];
              return newRecord;
            }),
          ]}
        >
          UPLOAD EXCLUSIONS FILE
        </DromoUploader>
      </Box>
      <Box
        sx={{ mt: 3 }}
      >
        <ImportHistory client={client} />
      </Box>
    </div>
  );
}

export default TransactionsExclusions;

TransactionsExclusions.propTypes = {
  client: PropTypes.string,
};
