import React from 'react';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import ClientForm from './ClientForm';

class ClientDropDown extends ClientForm {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
    };
  }

  render() {
    return (
      <div key={this.state.attribute}>
        {this.state.attribute in this.state.structure && (
        <>
          <InputLabel
            variant="standard"
            htmlFor="uncontrolled-native"
            required={this.state.required}
          >
            {this.state.label}
          </InputLabel>
          <NativeSelect
            onChange={this.updateClientState}
            disabled={this.state.isDisabled || this.props.isDisabled}
            id={this.state.attribute}
            defaultValue={this.state.data}
            inputProps={{ 'data-testid': this.state.testingId }}
          >
            {this.state.structure[this.state.attribute].map((val) => (
              <option key={val} value={String(val)}>
                {String(val)}
              </option>
            ))}
          </NativeSelect>
        </>
        )}
      </div>
    );
  }
}

export default ClientDropDown;
