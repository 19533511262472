// defines the operations available for each type
const operations = {
  stringOps: {
    eq: '=',
    ne: '!=',
    exists: 'exists',
    customsearch: 'contains',
  },
  boolOps: {
    eq: '=',
    ne: '!=',
  },
  numberOps: {
    eq: '=',
    ne: '!=',
    gt: '>',
    gte: '>=',
    lt: '<',
    lte: '<=',
  },
  awsdtOps: {
    gte: 'after',
    lte: 'before',
    between: 'between',
  },
};

// human readable labels for each type
const opLabels = {
  exists: 'exists',
  eq: '=',
  gt: '>',
  gte: '≥',
  lt: '<',
  lte: '≤',
  ne: '≠',
  customsearch: 'contains',
  between: 'between',
};

const valueFormatters = {
  awsDateTimeFormatter: (awsDT) => {
    // date and time no ms or timezone
    try {
      const { value } = awsDT;
      if (!value) {
        return '';
      }
      const timestamp = new Date(value);
      const formattedTimestamp = timestamp.toISOString().replace('T', ' ').split('.')[0];
      return formattedTimestamp;
    } catch (err) {
      return awsDT;
    }
  },
  dateFormatter: (awsDT) => {
    // just the date portion
    try {
      const { value } = awsDT;
      if (!value) {
        return '';
      }
      const timestamp = new Date(value);
      const day = timestamp.getDate();
      const month = timestamp.getMonth() + 1; // 0-indexed but we want 1-12
      const year = timestamp.getFullYear();
      return `${month}/${day}/${year}`;
    } catch (err) {
      return awsDT;
    }
  },
  currencyFormatter: (currency) => {
    try {
      const { value } = currency;
      if (!value) {
        return value;
      }
      const formatter = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    } catch (err) {
      return currency;
    }
  },
  numberFormatter: (number) => {
    // comma separated number so 1000 -> 1,000
    try {
      const { value } = number;
      const num = Number(value);
      const formattedNum = num.toLocaleString('en-US');
      return formattedNum;
    } catch (err) {
      return number;
    }
  },
  percentFormatter: (percent) => {
    try {
      const { value } = percent;
      if (value) {
        return `${value}%`;
      }
      return '';
    } catch (err) {
      return percent;
    }
  },
};

const clientColumns = [
  {
    field: 'code',
    headerName: 'Client',
    minWidth: 100,
    flex: 1,
  },
  {
    field: 'jiraname',
    headerName: 'Jira Project',
  },
  {
    field: 'fullname',
    headerName: 'Full Name',
    minWidth: 130,
    flex: 1,
  },
  {
    field: 'clienttype',
    headerName: 'Client Type',
  },
  {
    field: 'adaimember',
    headerName: 'AdAi Member',
  },
  {
    field: 'actblueids',
    headerName: 'Actblue IDs',
  },
  {
    field: 'actionsdatasource',
    headerName: 'Actions Data Source',
  },
  {
    field: 'adaimemberdate',
    headerName: 'AdAI Member Since',
  },
  {
    field: 'adaisuppresseddomains',
    headerName: 'AdAI Suppressed Domains',
  },
  {
    field: 'bingids',
    headerName: 'Bing Data Ids',
  },
  {
    field: 'codedicurl',
    headerName: 'Code Dictionary URL',
  },
  {
    field: 'curateautoupload',
    headerName: 'Curate Auto Upload',
  },
  {
    field: 'curatedays',
    headerName: 'Curate Days',
  },
  {
    field: 'curatefrequency',
    headerName: 'Curate Frequency',
  },
  {
    field: 'curatesubscriber',
    headerName: 'Curate Subscriber',
  },
  {
    field: 'emailexhaustdatasource',
    headerName: 'Email Exhaust Data Source',
  },
  {
    field: 'facebookids',
    headerName: 'Facebook IDs',
  },
  {
    field: 'facebookloc',
    headerName: 'Facebook Location',
  },
  {
    field: 'googleids',
    headerName: 'Google IDs',
  },
  {
    field: 'goopid',
    headerName: 'Goop IDs',
  },
  {
    field: 'hubsrcpath',
    headerName: 'Hub Source Path',
  },
  {
    field: 'mailchimpdir',
    headerName: 'Mailchimp Directory',
  },
  {
    field: 'ngpeadb',
    headerName: 'NGP EA Database',
  },
  {
    field: 'ngpeaserver',
    headerName: 'NGP EA Server',
  },
  {
    field: 'outbrainids',
    headerName: 'Outbrain IDs',
  },
  {
    field: 'pardotloc',
    headerName: 'Pardot Location',
  },
  {
    field: 'peopledatasource',
    headerName: 'People DataSource',
  },
  {
    field: 'salesforceloc',
    headerName: 'Salesforce Location',
  },
  {
    field: 'salsakey',
    headerName: 'Salsa Key',
  },
  {
    field: 'sfgid',
    headerName: 'SFG IDs',
  },
  {
    field: 'slackchannel',
    headerName: 'Slack Channel',
  },
  {
    field: 'timezone',
    headerName: 'Timezone',
  },
  {
    field: 'totemid',
    headerName: 'Totem IDs',
  },
  {
    field: 'transactionsdatasource',
    headerName: 'Transactions Datasource',
  },
  {
    field: 'twitterids',
    headerName: 'Twitter IDs',
  },
  {
    field: 'excludematchqualifier',
    headerName: 'Exclude from Match Qualifier',
  },
  {
    field: 'smsdatasource',
    headerName: 'SMS Datasource',
  },
  {
    field: 'isactive',
    headerName: 'Active',
  },
  {
    field: 'isstrategypartner',
    headerName: 'Strategy Partner',
  },
  {
    field: 'featurize',
    headerName: 'Featurize',
  },
  {
    field: 'classyorgid',
    headerName: "Classy Org ID's",
  },
  {
    field: 'ngpactbluemanualcodes',
    headerName: 'NGP Actblue Manual Codes',
  },
  {
    field: 'sharecapmaxshares',
    headerName: 'Share Cap Max Shares',
  },
  {
    field: 'sharecapmonths',
    headerName: 'Share Cap Months',
  },
  {
    field: 'tatangoid',
    headerName: 'Tatango ID',
  },
  {
    field: 'inboxmonsterrule',
    headerName: 'Inbox Monster Rule',
  },
  {
    field: 'campaignmonitorlocation',
    headerName: 'Campaign Monitor Location',
  },
  {
    field: 'pausedata',
    headerName: 'Pause Data',
  },
  {
    field: 'donorfloorvalue',
    headerName: 'Donor Floor',
  },
  {
    field: 'donorfloortype',
    headerName: 'Donor Floor Type',
  },
  {
    field: 'mmclients',
    headerName: 'Multi-Member Clients',
  },
  {
    field: 'stwaccountids',
    headerName: 'STW Subaccount IDs',
  },
  {
    field: 'notes',
    headerName: 'Notes',
  },
  {
    field: 'islowinventory',
    headerName: 'Is Low Inventory',
  },
  {
    field: 'updatedat',
    headerName: 'Updated At',
    format: 'AWSDate',
    valueFormatter: valueFormatters.dateFormatter,
    formatterName: 'dateFormatter',
  },
  {
    field: 'createdat',
    headerName: 'Created At',
    format: 'AWSDate',
    valueFormatter: valueFormatters.dateFormatter,
    formatterName: 'dateFormatter',
  },
];

const codeColumns = [
  {
    field: 'client_id',
    headerName: 'Client',
    format: 'String',
    sortable: false,
  },
  {
    field: 'code',
    headerName: 'Code',
    width: 200,
    format: 'String',
    sortable: false,
  },
  {
    field: 'source',
    headerName: 'Source',
    width: 200,
    format: 'String',
    sortable: false,
  },
  {
    field: 'audience',
    headerName: 'Audience',
    width: 150,
    format: 'String',
    sortable: false,
  },
  {
    field: 'audience_category',
    headerName: 'Audience Category',
    width: 150,
    format: 'String',
    sortable: false,
  },
  {
    field: 'revenue',
    headerName: 'Revenue',
    width: 120,
    format: 'Float',
    type: 'number',
    valueFormatter: valueFormatters.currencyFormatter,
    formatterName: 'currencyFormatter',
    sortable: false,
  },
  {
    field: 'revenue_category',
    headerName: 'Revenue Category',
    format: 'String',
    sortable: false,
  },
  {
    field: 'type',
    headerName: 'Revenue Type',
    width: 135,
    options: [
      'acquisition',
      'revenue',
      'acquisition_d2d',
      'revenue_d2d',
      'Fundraising',
      'Joint_Action',
      'Bundling',
      'Survey',
      'Other',
      'Petition',
      'Action',
      'p2p',
      'broadcast',
    ],
    format: 'options',
    sortable: false,
  },
  {
    field: 'fixed_cost_cpa_override',
    headerName: 'Fixed Cost CPA',
    format: 'Float',
    type: 'number',
    valueFormatter: valueFormatters.currencyFormatter,
    formatterName: 'currencyFormatter',
    sortable: false,
  },
  {
    field: 'fixed_cost_media_spend',
    headerName: 'Fixed Cost Media Spend',
    format: 'Float',
    type: 'number',
    valueFormatter: valueFormatters.currencyFormatter,
    formatterName: 'currencyFormatter',
    sortable: false,
  },
  {
    field: 'most_recent_spend',
    headerName: 'Most Recent Spend',
    format: 'Float',
    type: 'number',
    valueFormatter: valueFormatters.currencyFormatter,
    formatterName: 'currencyFormatter',
    sortable: false,
  },
  {
    field: 'most_recent_spend_date',
    headerName: 'Most Recent Spend Date',
    format: 'AWSDate',
    valueFormatter: valueFormatters.dateFormatter,
    formatterName: 'dateFormatter',
    sortable: false,
  },
  {
    field: 'platforms_with_spend',
    headerName: 'Platforms With Spend',
    options: ['facebook', 'google', 'twitter', 'bing'],
    format: 'options',
    sortable: false,
  },
  {
    field: 'total_spend',
    headerName: 'Total Spend',
    format: 'Float',
    type: 'number',
    valueFormatter: valueFormatters.currencyFormatter,
    formatterName: 'currencyFormatter',
    sortable: false,
  },
  {
    field: 'transactions',
    headerName: 'Transactions',
    format: 'Int',
    valueFormatter: valueFormatters.numberFormatter,
    formatterName: 'numberFormatter',
    sortable: false,
  },
  {
    field: 'most_recent_transaction_date',
    headerName: 'Most Recent Transaction Date',
    format: 'AWSDate',
    valueFormatter: valueFormatters.dateFormatter,
    formatterName: 'dateFormatter',
    sortable: false,
  },
  {
    field: 'page',
    headerName: 'Page(s)',
    format: 'String',
    sortable: false,
  },
  {
    field: 'actblue_managing_entities',
    headerName: 'Actblue Managing Entities',
    format: 'String',
    sortable: false,
    // potentially we will want to have these listed on multiple lines, like a list
  },
  {
    field: 'from_goop',
    headerName: 'From Goop',
    format: 'Boolean',
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    format: 'AWSDate',
    valueFormatter: valueFormatters.dateFormatter,
    formatterName: 'dateFormatter',
    sortable: false,
  },
];

// used for source records and searching sources
// see also enum channelcategory under graphql schema
const channelCategoryEnum = [
  'Ads',
  'Display',
  'Email',
  'Experimental',
  'Fixed_Cost',
  'Native',
  'Other',
  'Search',
  'SMS',
  'Social',
  'Swap',
  'Tapjoy',
  'Video',
  'Web',
  'Youtube',
];

const sourceColumns = [
  {
    field: 'client_id',
    headerName: 'Client',
    flex: 1,
    format: 'String',
    sortable: false,
  },
  {
    field: 'source',
    headerName: 'Source',
    flex: 1,
    format: 'String',
    sortable: false,
  },
  {
    field: 'channel',
    headerName: 'Channel',
    flex: 1,
    format: 'String',
    sortable: false,
  },
  {
    field: 'channel_category',
    headerName: 'Channel Category',
    flex: 1,
    options: channelCategoryEnum,
    format: 'options',
    sortable: false,
  },
  {
    field: 'from_goop',
    headerName: 'From Goop',
    format: 'Boolean',
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    format: 'AWSDate',
    valueFormatter: valueFormatters.dateFormatter,
    formatterName: 'dateFormatter',
    sortable: false,
  },
];

const sumBySourceColumns = [
  {
    field: 'date',
    headerName: 'Date',
    format: 'AWSDate',
    valueFormatter: valueFormatters.dateFormatter,
    formatterName: 'dateFormatter',
  },
  {
    field: 'channel',
    headerName: 'Channel',
    format: 'String',
  },
  {
    field: 'channel:category',
    headerName: 'Channel:Category',
    format: 'String',
  },
  {
    field: 'category',
    headerName: 'Category',
    format: 'String',
  },
  {
    field: 'gifts',
    headerName: 'Gifts',
    type: 'number',
    groupable: false,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    groupable: false,
    type: 'Float',
    valueFormatter: valueFormatters.currencyFormatter,
    formatterName: 'currencyFormatter',
  },
  {
    field: '% of total',
    headerName: '% of total',
    groupable: false,
    format: 'Float',
    formatterName: 'percentFormatter',
    valueFormatter: valueFormatters.percentFormatter,
  },
  {
    field: 'code',
    headerName: 'Code',
    format: 'String',
  },
];

const transactionColumns = [
  {
    field: 'client_id',
    headerName: 'Client',
    format: 'String',
    sortable: false,
  },
  {
    field: 'email',
    headerName: 'Email',
    format: 'String',
    sortable: false,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    type: 'number',
    format: 'Float',
    valueFormatter: valueFormatters.currencyFormatter,
    formatterName: 'currencyFormatter',
    sortable: false,
  },
  {
    field: 'exclude_flag',
    headerName: 'Excluded',
    format: 'Boolean',
    sortable: false,
  },
  {
    field: 'code',
    headerName: 'Code',
    width: 300,
    format: 'String',
    sortable: false,
  },
  {
    field: 'code_source',
    headerName: 'Source',
    width: 300,
    format: 'String',
    sortable: false,
    excludeFromSearch: true,
  },
  {
    field: 'foreign_platform',
    headerName: 'Transaction Platform',
    format: 'String',
    sortable: false,
  },
  {
    field: 'foreign_client_key',
    headerName: 'Client Platform ID',
    format: 'String',
    sortable: false,
  },
  {
    field: 'foreign_transaction_key',
    headerName: 'Transaction ID',
    format: 'String',
    sortable: false,
  },
  {
    field: 'recurrence',
    headerName: 'Recurrence Number',
    format: 'Int',
    sortable: false,
  },
  {
    field: 'recurring_period',
    headerName: 'Recurring Period',
    format: 'String',
    sortable: false,
  },
  {
    field: 'recurring_type',
    headerName: 'Recurring Type',
    format: 'String',
    sortable: false,
  },
  {
    field: 'transaction_dt',
    headerName: 'Transaction Date',
    format: 'AWSDate',
    formatterName: 'dateFormatter',
    valueFormatter: valueFormatters.dateFormatter,
    sortable: false,
  },
  {
    field: 'refund_flag',
    headerName: 'Refunded',
    format: 'Boolean',
    sortable: false,
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    format: 'AWSDate',
    valueFormatter: valueFormatters.dateFormatter,
    formatterName: 'dateFormatter',
    sortable: false,
  },
  {
    field: 'mobile_flag',
    headerName: 'Mobile Flag',
    format: 'Boolean',
    sortable: false,
  },
];

const insightsColumns = [
  // pinned
  {
    field: 'updated_dt',
    headerName: 'updated_dt',
    format: 'AWSDateTime',
    formatterName: 'awsDateTimeFormatter',
    valueFormatter: valueFormatters.awsDateTimeFormatter,
    minWidth: 160,
  },
  {
    field: 'client_type',
    headerName: 'client_type',
    format: 'String',
    width: 85,
  },
  {
    field: 'client',
    headerName: 'client',
    format: 'String',
  },
  {
    field: 'jira_ticket_num',
    headerName: 'jira_ticket_num',
    format: 'String',
    minWidth: 75,
  },
  {
    field: 'first_delivery_date',
    headerName: 'first_delivery_date',
    format: 'AWSDate',
    formatterName: 'dateFormatter',
    valueFormatter: valueFormatters.dateFormatter,
    minWidth: 160,
  },
  {
    field: 'delivery_month',
    headerName: 'Delivery Month',
    format: 'Int',
  },
  {
    field: 'delivery_year',
    headerName: 'Delivery Year',
    format: 'Int',
  },
  // not pinned
  {
    field: 'first_uploaded_date',
    headerName: 'first_uploaded_date',
    format: 'AWSDate',
    formatterName: 'dateFormatter',
    valueFormatter: valueFormatters.dateFormatter,
    minWidth: 160,
  },
  {
    field: 'emails_acquired',
    headerName: 'emails_acquired',
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
    format: 'Int',
  },
  {
    field: 'spend',
    headerName: 'spend',
    format: 'Float',
    type: 'number',
    formatterName: 'currencyFormatter',
    valueFormatter: valueFormatters.currencyFormatter,
  },
  {
    field: 'revenue',
    headerName: 'revenue',
    format: 'Float',
    type: 'number',
    formatterName: 'currencyFormatter',
    valueFormatter: valueFormatters.currencyFormatter,
  },
  {
    field: 'ROAS',
    headerName: 'ROAS',
    format: 'Float',
    formatterName: 'percentFormatter',
    valueFormatter: valueFormatters.percentFormatter,
  },
  {
    field: 'sends',
    headerName: 'sends',
    format: 'Int',
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'pixel_loads',
    headerName: 'pixel_loads',
    format: 'Int',
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'pixel_load_rate',
    headerName: 'pixel_load_rate (%)',
    format: 'Float',
    formatterName: 'percentFormatter',
    valueFormatter: valueFormatters.percentFormatter,
  },
  {
    field: 'opens',
    headerName: 'opens',
    format: 'Int',
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'open_rate',
    headerName: 'open_rate',
    format: 'Float',
    formatterName: 'percentFormatter',
    valueFormatter: valueFormatters.percentFormatter,
  },
  {
    field: 'clicks',
    headerName: 'clicks',
    format: 'Int',
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'click_rate',
    headerName: 'click_rate',
    format: 'Float',
    formatterName: 'percentFormatter',
    valueFormatter: valueFormatters.percentFormatter,
  },
  {
    field: 'Unsubs',
    headerName: 'Unsubs',
    format: 'Int',
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'donors',
    headerName: 'donors',
    format: 'Int',
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'contributions',
    headerName: 'contributions',
    format: 'Int',
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'average_gift',
    headerName: 'average_gift',
    format: 'Float',
    type: 'number',
    formatterName: 'currencyFormatter',
    valueFormatter: valueFormatters.currencyFormatter,
  },
  {
    field: 'ROAS_Over_Days',
    headerName: 'ROAS_Over_Days',
    format: 'Float',
    minWidth: 150,
    formatterName: 'percentFormatter',
    valueFormatter: valueFormatters.percentFormatter,
  },
  {
    field: 'Days_to_break_even',
    headerName: 'Days_to_break_even',
    format: 'Int',
    minWidth: 150,
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'Break_even_date',
    headerName: 'Break_even_date',
    format: 'AWSDateTime',
    formatterName: 'dateFormatter',
    valueFormatter: valueFormatters.dateFormatter,
    minWidth: 160,
  },
  {
    field: 'Projected_days_to_break_even',
    headerName: 'Projected_days_to_break_even',
    format: 'Int',
    minWidth: 175,
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'Projected_break_even_date',
    headerName: 'Projected_break_even_date',
    format: 'AWSDateTime',
    formatterName: 'dateFormatter',
    valueFormatter: valueFormatters.dateFormatter,
    minWidth: 160,
  },
  {
    field: 'gifts_over_opens',
    headerName: 'gifts_over_opens (%)',
    format: 'Float',
    minWidth: 125,
    formatterName: 'percentFormatter',
    valueFormatter: valueFormatters.percentFormatter,
  },
  {
    field: 'contributions_over_sends',
    headerName: 'contributions_over_sends',
    format: 'Float',
    minWidth: 175,
    formatterName: 'percentFormatter',
    valueFormatter: valueFormatters.percentFormatter,
  },
  {
    field: 'emails_messaged',
    headerName: 'emails_messaged',
    format: 'Int',
    minWidth: 75,
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'pixel_load_emails',
    headerName: 'pixel_load_emails',
    format: 'Int',
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'open_emails',
    headerName: 'open_emails',
    format: 'Int',
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'click_emails',
    headerName: 'click_emails',
    format: 'Int',
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'optouts',
    headerName: 'optouts',
    format: 'Int',
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'optout_emails',
    headerName: 'optout_emails',
    format: 'Int',
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'optout_rate',
    headerName: 'optout_rate',
    format: 'Float',
    formatterName: 'percentFormatter',
    valueFormatter: valueFormatters.percentFormatter,
  },
  {
    field: 'hard_bounces',
    headerName: 'hard_bounces',
    format: 'Int',
    minWidth: 75,
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'hard_bounce_emails',
    headerName: 'hard_bounce_emails',
    format: 'Int',
    minWidth: 100,
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'hard_bounce_rate',
    headerName: 'hard_bounce_rate',
    format: 'Float',
    minWidth: 75,
    formatterName: 'percentFormatter',
    valueFormatter: valueFormatters.percentFormatter,
  },
  {
    field: 'soft_bounces',
    headerName: 'soft_bounces',
    format: 'Int',
    minWidth: 75,
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'soft_bounce_emails',
    headerName: 'soft_bounce_emails',
    format: 'Int',
    minWidth: 75,
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'soft_bounce_rate',
    headerName: 'soft_bounce_rate',
    format: 'Float',
    formatterName: 'percentFormatter',
    valueFormatter: valueFormatters.percentFormatter,
  },
  {
    field: 'spams',
    headerName: 'spams',
    format: 'Int',
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'spam_emails',
    headerName: 'spam_emails',
    format: 'Int',
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'spam_rate',
    headerName: 'spam_rate',
    format: 'Float',
    formatterName: 'percentFormatter',
    valueFormatter: valueFormatters.percentFormatter,
  },
  {
    field: 'send_emails_past_30',
    headerName: 'send_emails_past_30',
    format: 'Int',
    formatterName: 'numberFormatter',
    valueFormatter: valueFormatters.numberFormatter,
  },
  {
    field: 'cost_per_donor',
    headerName: 'cost_per_donor',
    format: 'Float',
    formatterName: 'currencyFormatter',
    valueFormatter: valueFormatters.currencyFormatter,
  },
];

const cohortColumns = [
  {
    field: 'cohort',
    headerName: 'cohort',
    format: 'String',
  },
  {
    field: 'cohort_name',
    headerName: 'cohort_name',
    format: 'String',
  },
];

const reacCohortColumn = [
  {
    field: 'recurring_flag',
    headerName: 'recurring_flag',
    format: 'String',
  },
];

const insightsNonCohortColumns = insightsColumns;
const insightsCohortColumns = insightsColumns.concat(cohortColumns);
const insightsReacCohortColumns = insightsCohortColumns.concat(reacCohortColumn);

const importHistoryColumns = [
  {
    field: 'client',
    headerName: 'Client',
    format: 'String',
  },
  {
    field: 'csv_name',
    headerName: 'CSV name',
    width: 150,
    format: 'String',
  },
  {
    field: 'timestamp',
    headerName: 'Timestamp',
    format: 'String',
  },
  {
    field: 'status',
    headerName: 'Status',
    format: 'String',
  },
  // generated in code, not in db
  {
    field: 'exclusionCount',
    headerName: 'Exclusion count',
    width: 150,
    format: 'String',
  },
  {
    field: 'error',
    headerName: 'Error',
    width: 200,
    format: 'String',
  },
  {
    field: 'warning',
    headerName: 'Warning',
    width: 200,
    format: 'String',
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    format: 'String',
  },
  {
    field: 'updatedAt',
    headerName: 'Updated at',
    format: 'String',
  },

];

export {
  operations,
  opLabels,
  valueFormatters,
  clientColumns,
  codeColumns,
  sourceColumns,
  sumBySourceColumns,
  transactionColumns,
  insightsNonCohortColumns,
  insightsCohortColumns,
  insightsReacCohortColumns,
  importHistoryColumns,
  channelCategoryEnum,
};
